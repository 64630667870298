import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {extractAndUpperCase} from '../components/Global'
const Home = () => {

  const API_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT || "http://production";

  const [data,setdata] = useState([]);
  const [smt, setsmt] = useState("");
  const getData = () => {
    axios
      .get(`${API_ENDPOINT}/api/job-list`)
      .then((res) => {
        setdata(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setsmt("owl-carousel");
      window.portal();
    }, 1000);
  }, [data]);

  
  return (
    <>
      <section className="banner_banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-7 col-12">
              <div className="banner_banner_contain ">
                <h1>Find the Right candidate for your institution</h1>
                <p>Discover 1 lakh+ experienced candidates</p>
                <form>
                  <div className="banner_banner_input_box">
                    <div className="row align-items-center">
                      <div className="col-lg-5 col-md-5 col-4">
                        <div className="banner_search">
                          <input
                            type="search"
                            placeholder="search job by skill"
                            name="byskill"
                            id=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-4 col-4">
                        <div className="location_search">
                          <input
                            type="search"
                            placeholder="All cities"
                            name="bylocation"
                            id=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-3">
                        <div className="fin_btn">
                          <NavLink to="/jobs">
                            <button type="submit"> Find</button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="rcfd">
                  <p>
                    popular &nbsp; <span>administrative assistant</span>{" "}
                    <span>counseller</span> <span>librarian</span>{" "}
                    <span>office coordinator</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="comment"></div>
              <div className="comgold"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="citiessecion py-5">
        <div className="container my-4">
          <div className="row text-center">
            <div className="heading mb-5">
              <h2>Find Candidates/Jobs in your City</h2>
            </div>

            <div id="demos">
              <div className={`findjobs   ${smt}`}>
                {data.map((item, index) => {
                  return (
                    <div key={index} className="citybox p-4">
                      <NavLink to="/jobs">
                        <img src="assets/images/Layer 4.png" alt="chennai" />
                        <h5>Chennai</h5>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" py-5">
        <div className="container my-4">
          <div className="row text-center">
            <div className="heading mb-5">
              <h2>Trending Job Categories</h2>
            </div>

            <div id="demos">
              <div className={` jobcategory ${smt} job_section `}>
                {data.map((item, index) => {
                  return (
                    <div key={index} className="citybox py-5"> 
                      <NavLink to="/jobs">
                        <h5>Coding Teacher</h5>
                        <p>1200+ Openings</p>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="citiessecion py-5">
        <div className="container my-4">
          <div className="row ">
            <div className="heading mb-5">
              <h2>Trending openings</h2>
            </div>

            <div id="demos">
              <div className={`  applyjob ${smt} news_section`} >
                {data.slice(0,4).map((item, index) => {
                  return (
                    <div key={index} className="jklojni">
                      <div className="dpuper d-flex">
                        <div className="dpimg">
                        <span> {extractAndUpperCase(item.recruiter.name)}</span>
                         
                        </div>
                        <div className="dateb">
                          <p>12 Sep</p>
                        </div>
                      </div>
                      <div className="contentcard">
                        <NavLink to={`/jobdetail/${item._id}`}>
                          <h2>{item.title}</h2>
                        </NavLink>
                        <p>New Delhi</p>
                        <p>
                          Experience : 2+ years of teaching experience or
                          Freshers . Work Timings: 9 AM 6 PM/ 10 AM 7 PM (and
                          alternate Saturdays half)
                        </p>
                      </div>
                      <div className="detailscard">
                        <div className="jobtype d-flex">
                          <div className="logojobtype">
                            <img
                              src="assets/images/Icon feather-briefcase.png"
                              alt="briefcase icon"
                            />
                          </div>
                          <div className="namejobtype">
                            <p>Full time job</p>
                          </div>
                        </div>
                        <div className="jobtype d-flex">
                          <div className="logojobtype">
                            <img
                              src="assets/images/Icon metro-inr.png"
                              alt="rupees"
                            />
                          </div>
                          <div className="namejobtype">
                            <p>6,00,000-8,00,000</p>
                          </div>
                        </div>
                        <NavLink to="/jobdetail">
                          <button className="applyjobbtn">Apply Job</button>
                        </NavLink>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="advancebla pb-5">
        <div className="col-lg-9 col-md-9 mx-auto col-12">
          <div className="row pt-5 justify-content-md-around">
            <div className="col-lg-6 col-md-6 dekhodekho">
              <div className="proflcard text-center one">
                <img
                  src="assets/images/shutterstock_2055614918 copy.png"
                  className="kkmio"
                />
                <h2>Madhav</h2>
                <p>Admin Executive</p>
                <div className="dsds">✩ 5.0</div>
              </div>
              <div className="proflcard text-center two">
                <img src="assets/images/Mask Group 4.png" className="kkmio" />
                <h2>Madhav</h2>
                <p>Admin Executive</p>
                <div className="dsds">✩ 5.0</div>
              </div>
              <div className="proflcard text-center three">
                <img
                  src="assets/images/shutterstock_2157123223 copy.png"
                  className="kkmio"
                />
                <h2>Madhav</h2>
                <p>Admin Executive</p>
                <div className="dsds">✩ 5.0</div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-12">
              <div className="onfds">
                <h2 className="mb-4">
                  Advanced solutions and professional talent for educational
                </h2>
                <h4>Register Account</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </p>
                <h4>Register Account</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </p>
                <h4>Register Account</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </p>
                <button className="postajobnm">Post a Job</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="jnijnkmjh">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="onfds dsdasasq">
                <h2 className="mb-4">
                  Advanced solutions and professional talent for educational
                </h2>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </p>

                <button className="postajobnm">Apply Job</button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="stemoddf d-flex">
                <div className="inds">01</div>
                <div className="copy">
                  <img
                    src="assets/images/Icon metro-files-empty.png"
                    className="wsfsf"
                    alt="file"
                  />
                </div>
                <div className="cndson">
                  <h4>Register Account</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt
                  </p>
                </div>
              </div>
              <div className="stemoddf d-flex">
                <div className="inds">02</div>
                <div className="copy">
                  <img
                    src="assets/images/Icon metro-files-empty.png"
                    className="wsfsf"
                    alt="file"
                  />
                </div>
                <div className="cndson">
                  <h4>Profile Verification</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt
                  </p>
                </div>
              </div>
              <div className="stemoddf d-flex">
                <div className="inds">03</div>
                <div className="copy">
                  <img
                    src="assets/images/Icon metro-files-empty.png"
                    className="wsfsf"
                    alt="file"
                  />
                </div>
                <div className="cndson">
                  <h4>Start Hunting jobs</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="citiessecion py-5">
        <div className="container my-4">
          <div className="row ">
            <div className="heading mb-5">
              <h2>Trending openings</h2>
            </div>

            <div id="demos">
              <div className={`applyjob ${smt} news_section citiessecion`}>
                {data.map((item, index) => {
                  return (
                    <div key={index} className="jklojni">
                      <div className="dpuper d-flex">
                        <div className="dpimg">
                          <span>DPS</span>
                        </div>
                        <div className="dateb">
                          <p>12 Sep</p>
                        </div>
                      </div>
                      <div className="contentcard">
                        <NavLink to="/jobdetail">
                          <h2>Early Years Facilitators (Teachers)</h2>
                        </NavLink>
                        <p>New Delhi</p>
                        <p>
                          Experience : 2+ years of teaching experience or
                          Freshers . Work Timings: 9 AM 6 PM/ 10 AM 7 PM (and
                          alternate Saturdays half)
                        </p>
                      </div>
                      <div className="detailscard">
                        <div className="jobtype d-flex">
                          <div className="logojobtype">
                            <img
                              src="assets/images/Icon feather-briefcase.png"
                              alt="briefcase icon"
                            />
                          </div>
                          <div className="namejobtype">
                            <p>Full time job</p>
                          </div>
                        </div>
                        <div className="jobtype d-flex">
                          <div className="logojobtype">
                            <img
                              src="assets/images/Icon metro-inr.png"
                              alt="rupees"
                            />
                          </div>
                          <div className="namejobtype">
                            <p>6,00,000-8,00,000</p>
                          </div>
                        </div>
                        <NavLink to="/jobdetail">
                          <button className="applyjobbtn">Apply Job</button>
                        </NavLink>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sdfwef">
        <div className="container fsknslm">
          <div className="row align-items-md-end">
            <div className="col-lg-6 col-md-9">
              <div className="convdsa">
                <h2>Ready to level-up?</h2>
                <p>
                  Experience : 2+ years of teaching experience or Freshers .
                  Work Timings: 9 AM 6 PM/ 10 AM 7 PM (and alternate Saturdays
                  half)
                </p>
                <button className="huntingj">Start hunting Jobs</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-5">
          <div className="row sdknfkjadbk">
            <h2 className="text-center my-5">Find schools that deserve you!</h2>

            <div id="demos">
              <div className={`school ${smt} school_section`}>
                {data.map((item, index) => {
                  return (
                    <div key={index} className="card-onenso">
                      <img
                        src="assets/images/shutterstock_251068444.png"
                        className="img-fluid"
                        alt="school"
                      />

                      <div className="row dsrfed">
                        <div className="col-lg-2 col-md-3 col-2">
                          <div className="dsdfsd">
                            <h4 className="xcxc">DPS</h4>
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-7">
                          <h5 className="mt-3">
                            <b>Gurgaon Public School</b>
                          </h5>
                          <p>Higher educational firm</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-3 sdfsdfa">
                          <div className="dsds">✩ 5.0</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="vjibtvjen" id="demos">
        <div className="vjibtn_wrap">
          <h2 className="text-center">
            We help lakhs of users find their perfect company every month
          </h2>
          <h5 className="text-center">Here’s what they say about us</h5>
        </div>

        <div className={`${smt} vgbhjknj`}>
          {data.map((item, index) => {
            return (
              <div key={index} className="crslcard ">
                <p>
                  "Writing a will becomes imperative to ensuring that an estate
                  is devolved as per the wishes of the Testator. In the absence
                  of the same, succession of their assets.
                </p>
                <img
                  src="assets/images/dnt_icn-2.png"
                  className="img-fluid"
                  alt="icon"
                />
                <h4>Name goes here</h4>
                <p>BUSINESSMAN FROM DELHI</p>
              </div>
            );
          })}
        </div>
      </section>

      <section className="slider_section">
        <div className="slider_title">
          <h3>Get the real insights from the education firms</h3>
          <p>Here’s what they say about us</p>
        </div>
        <div className="col-lg-10 mx-auto col-md-10 " id="demos">
          <div className={`slide_wrap ${smt} news_section`}>
            {data.map((item, index) => {
              return (
                <div key={index} className=" slide_card">
                  <div className="slide_title">
                    <div className="slide_img">
                      <img
                        src="assets/images/maxresdefault.png"
                        alt="news_insights_1"
                        className="img-fluid"
                      />
                    </div>
                    <div className="slide_name">
                      <h3>Legal battles for family if die intestate</h3>
                      <p>
                        Writing a will becomes imperative to ensuring that an
                        estate is devolved as per the wishes of the Testator. In
                        the absence of the same, succession of their assets.
                      </p>
                    </div>
                  </div>
                  <div className="slide_btn">
                    <NavLink to="/blogs">
                      read more{" "}
                      <span>
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    </NavLink>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
