import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";

const Login = () => {
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || "http://production";
  const nav = useNavigate();
  const [state, setState] = useState({
    user: "",
    password: "",
  });
  const loginSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: state.user,
      password: state.password,
    };
    axios
      .post(`${API_ENDPOINT}/auth/login`, data)
      .then((res) => {
        if (res.status == 200) {
          localStorage.setItem("Token", res.data.token);
          localStorage.setItem("userType", res.data.type);
          if(res.data.type == 'recruiter')
          {
            window.location.href = "http://localhost:3001/dashboard";
            return;
          }
          swal("Success", "Login Successfully", "success", {
            buttons: false,
            timer: 2000,
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        } else {
          alert("something went wrong");
        }
      })
      .catch((err) => {
        swal("error", err.response.data.message, "error", {
          buttons: false,
          timer: 2000,
        });
      });
  };

  const handleForm = (e) => {
    const { name, value } = e.target;

    setState((data) => ({
      ...data,
      [name]: value,
    }));
  };

  console.log(state);
  const id = useParams();
  console.log(id.id);

  const [params, setParams] = useState("");

  useEffect(() => {
    setParams(id.id);
  });

  return (
    <>
      <div class="user-landing-otp">
        <div class="col-lg-9 mx-auto">
          <div class="reg_wrps">
            <div class="row justify-content-around">
              <div class="col-lg-8 col-md-8 col-12">
                <div class="reg_frm">
                  <div class="col-lg-10 col-md-10 col-12">
                    <div class="reg_title">
                      <h2>Login {params}</h2>
                    </div>
                    <form onSubmit={loginSubmit}>
                      <div className="login_">
                        <label htmlFor="">Username</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="Enter Username"
                          name="user"
                          onChange={handleForm}
                        />
                      </div>

                      <div className="login_">
                        <label htmlFor="">Password</label>
                        <input
                          type="password"
                          className="form-control form-control-md"
                          placeholder="Enter Password"
                          name="password"
                          onChange={handleForm}
                        />
                      </div>
                      <div class=" otp_btn">
                        <button type="submit">Login</button>
                      </div>
                    </form>
                  </div>

                  <div className="mt-3">
                   
                  Don't Have Account  
                  {id.id=='employer'?(<>
                    <NavLink to="#">SignUp</NavLink>
                  </>):(<>
                    <NavLink to="/register">SignUp</NavLink>
                  </>)}
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
