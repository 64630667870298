import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
    <section className="citiessecion py-5">
        <div className="container my-4">
          <div className="row text-center">
            <div className="heading mb-5">
              <h2>Privacy Policy</h2>
            </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
              <section>
        <p>This Privacy Policy outlines how [Your Job Portal Name] collects, uses, and protects your personal information when you use our services. By using our platform, you consent to the practices described in this policy.</p>
    </section>

    <section>
        <h2>Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
            <li><strong>Personal Information:</strong> This includes your name, contact details, and any other information you voluntarily provide.</li>
            <li><strong>Resume and Profile Information:</strong> If you choose to upload your resume or create a profile, we will collect information about your skills, qualifications, and employment history.</li>
            <li><strong>Usage Information:</strong> We may collect data on how you interact with our platform, such as pages visited, searches performed, and job applications submitted.</li>
        </ul>
    </section>

    <section>
        <h2>How We Use Your Information</h2>
        <p>We use your information for the following purposes:</p>
        <ul>
            <li><strong>Matching:</strong> To match your skills and qualifications with job opportunities.</li>
            <li><strong>Communication:</strong> To communicate with you regarding your account, job applications, and other relevant updates.</li>
            <li><strong>Improvement:</strong> To analyze user behavior and improve our platform's functionality and user experience.</li>
        </ul>
    </section>

    <section>
        <h2>Information Sharing</h2>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share your information with trusted third parties who assist us in operating our website and providing services, as long as those parties agree to keep this information confidential.</p>
    </section>

    <section>
        <h2>Security Measures</h2>
        <p>We take the security of your personal information seriously and implement measures to protect it from unauthorized access, disclosure, alteration, and destruction.</p>
    </section>

    <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time, and any changes will be reflected on this page. Please review this policy periodically to stay informed about how we are protecting and using your information.</p>
    </section>

    <section>
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about our Privacy Policy, please contact us at [your contact email address].</p>
    </section>
              </div>
            </div>
          </div>
      </section>
    </>
  )
}

export default PrivacyPolicy