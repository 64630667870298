import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Otp = (props) => {
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || "http://production";
  const [otp, setOTP] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  const [state,setstate]=useState();
  const handleOTP = (e) => {
    
    const target= e.target;
    setOTP({
      ...otp,
      [target.name]:target.value
  })
  };

  const[otpid,setid]=useState();
  const [timer, setTimer] = useState(120);
  
  const getotponnumber =()=>{
    console.log(phonenumber)
    if(phonenumber !='' && phonenumber.length == 13){
     
      const body = {
        phonenumber: phonenumber
      }
      axios.post(`${API_ENDPOINT}/api/getotp`,body)
      .then((res)=>{
console.log(res)
alert(res.data.data.otp)
toast.success('Otp Send to' + phonenumber);
setid(res.data.id);
setvisible(false);
setTimer(120);
setOTP({
  otp1: "",
  otp2: "",
  otp3: "",
  otp4: "",
  otp5: "",
  otp6: "",
})

      })
      .catch((err)=>{
console.log(err)
      })
    }
    else{
      toast.error('please Enter correct phone Number')
    }
  }
    const [ButtonClass,setButtonClass]=useState('enabled')
  
  useEffect(()=>{
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalId);
         setButtonClass('disabled');
          // You can add additional logic when the timer reaches 0 here
          return 0; // Ensure the timer doesn't go negative
        }
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  },[timer])

  useEffect(()=>{
    setstate(props.register);
    console.log(props.otpid)
    setid(props.otpid)
    setphonenumber(props.register.contactNumber)
  },[])

  const submitOTP = () => {
    const myotp = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}${otp.otp6}`;
    console.log(myotp);
    console.log(otpid)
   if(myotp !=''){
    const body = {
      
      id: otpid,
      otp: myotp
  }
  console.log(body)
  axios.post(`${API_ENDPOINT}/api/verify-otp`,body)
  .then((res)=>{
    console.log(res.data);
    if(res.data.Verified)
    {
      
      axios
      .post(`${API_ENDPOINT}/auth/signup`,state)
      .then((response) => {
       if(response.status == 200){
        swal("Success", response.data.message, "success", {
          buttons: false,
          timer: 2000,
        });
        localStorage.setItem('Token',response.data.token);
        localStorage.setItem('',response.data.type)
        setTimeout(() => {
          window.location.href = "/adduserdetail";
        }, 2000);
       }
       else{
        swal("Success", response.data.message, "error", {
          buttons: false,
          timer: 2000
        });
        window.location.href = "/register";
        
       }
       
        // console.log(regData);
      })
      .catch((error) => {
        console.error(error);
        swal("error",error.response.data.message, "error", {
          buttons: false,
         
        });
      });
    } 
  })
  .catch((err)=>{
    console.log(err)
  })
   }
   else{
    toast.error("Please Enter Valid OTP")
   }
  };
  const [visible,setvisible]=useState(false);
  const handlefieldsotpre = ()=>{
    setvisible(true);
  }

  const [phonenumber,setphonenumber]=useState();

 const changephonenumberinput =(e)=>{
  setphonenumber(e.target.value);
 }

  return (
    <>
      <div class="user-landing-otp">
        <div class="col-lg-9 mx-auto">
          <div class="reg_wrps">
            <div class="row justify-content-around">

            {visible?(<>
              <div class="col-lg-8 col-md-8 col-12">
                <div class="reg_frm">
                  <div class="col-lg-10 col-md-10 col-12">
                    <div class="reg_title">
                      <h2>Enter OTP</h2>
                    </div>
                    <div class="otp_lne">
                      <p>change phone number to send otp</p>
                    
                    </div>
                    <input
                          type="number" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          className="form-control form-control-md my-3"
                          placeholder="Enter Phone Number"
                          name="name"
                          style={{width: "50%"}}
                          value={phonenumber}
                          onChange={changephonenumberinput}
                        />
                   
                    
                    <div class="otp_btn">
                      <button type="button" onClick={getotponnumber}>
                        Send OTP
                      </button>
                    </div>
                    
                  </div>
                </div>
              </div>
                      
                    </>):(<>
                      <div class="col-lg-8 col-md-8 col-12">
                <div class="reg_frm">
                  <div class="col-lg-10 col-md-10 col-12">
                    <div class="reg_title">
                      <h2>Enter OTP</h2>
                    </div>
                    <div class="otp_lne">
                      <p>An OTP sent to your mobile number {phonenumber} </p>
                    
                     
                      <NavLink to="#" onClick={handlefieldsotpre}>Change Number</NavLink>
                    </div>

                   
                    <div class="otp_inpt">
                      <input
                        type="text"
                        class="otp-field"
                        maxlength="1"
                        name="otp1"
                        onChange={handleOTP}
                      />
                      <input
                        type="text"
                        class="otp-field"
                        maxlength="1"
                        name="otp2"
                        onChange={handleOTP}
                      />
                      <input
                        type="text"
                        class="otp-field"
                        maxlength="1"
                        name="otp3"
                        onChange={handleOTP}
                      />
                      <input
                        type="text"
                        class="otp-field"
                        maxlength="1"
                        name="otp4"
                        onChange={handleOTP}
                      />
                      <input
                        type="text"
                        class="otp-field"
                        maxlength="1"
                        name="otp5"
                        onChange={handleOTP}
                      />
                      <input
                        type="text"
                        class="otp-field"
                        maxlength="1"
                        name="otp6"
                        onChange={handleOTP}
                      />
                    </div>

                    <div class="otprsnt">
                      <p>Resent code</p>
                      <span>{timer} seconds</span>
                    </div>
                    {
                      timer<=0?(<>
                      <div class="otp_btn">
                      <button type="button" onClick={getotponnumber}>
                        Resend Otp
                      </button>
                    </div>
                      </>):(<>
                        <div class="otp_btn">
                      <button type="button"  onClick={submitOTP}>
                        next step
                      </button>
                    </div>
                      </>)
                    }
                    
                  </div>
                </div>
              </div>
                    </>)}

            
              <div class="col-lg-3 col-md-8 col-12">
                <div class="reg_acc">
                  <div class="reg_acc_title">
                    <h2>After Registering Account you will be able to</h2>
                  </div>

                  <div class="rg_acc_lst">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-lg-2 col-md-1 col-3">
                            <div class="acc_img">
                              <i class="fa-solid fa-check"></i>
                            </div>
                          </div>
                          <div class="col-lg-10 col-md-10 col-9">
                            <div class="acc_text">
                              <h3>I am fresher</h3>
                              <p>
                                We will send you relevant job application and
                                update on this email
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-lg-2 col-md-1 col-3">
                            <div class="acc_img">
                              <i class="fa-solid fa-check"></i>
                            </div>
                          </div>
                          <div class="col-lg-10 col-md-10 col-9">
                            <div class="acc_text">
                              <h3>I am fresher</h3>
                              <p>
                                We will send you relevant job application and
                                update on this email
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-lg-2 col-md-1 col-3">
                            <div class="acc_img">
                              <i class="fa-solid fa-check"></i>
                            </div>
                          </div>
                          <div class="col-lg-10 col-md-10 col-9">
                            <div class="acc_text">
                              <h3>I am fresher</h3>
                              <p>
                                We will send you relevant job application and
                                update on this email
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
rtl={false}
pauseOnHover={true}
theme="light"
/>
    </>
  );
};

export default Otp;
