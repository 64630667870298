import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="col-lg-9 mx-auto">
                    <div className="footer_job_com">
                        <div className="job_comity text-center">
                            <h3>Join India's No.1 Education job community</h3>
                            <p>Join tens of thousands of fellow educational community in more than 100 cities of India</p>
                        </div>

                        <div className="col-12 col-lg-11 mx-auto">
                            <div className="job_inpt">
                                <div className="row justify-content-lg-center">
                                    <div className="col-12 col-lg-8">
                                        <input type="text" className="form-control form-control-md" placeholder="YOUR EMAIL" />
                                    </div>
                                    <div className="col-12 col-lg-2 text-lg-start text-center">
                                        <button type="button">subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-9 mx-auto">
                    <div className="footer_wrapper">
                        <div className="row justify-content-lg-around">
                            <div className="col-lg-2">
                                <div className="footer_links">
                                    <ul>
                                        <li>
                                            <NavLink to="#">Career Advice</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Browse Jobs</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Browse Companies</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Salaries</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Events</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Work with us</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Countries</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/about">About</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/helpcenter">Help Centre</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="footer_links">
                                    <ul>
                                        <li>
                                            <NavLink to="/about">About</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/helpcenter">Help Centre</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">ESG at Indeed</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Guidelines for safe job search</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="footer_links">
                                    <ul>
                                        <li>
                                            <NavLink to="/about">About Us</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Plans</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/contact">Contact</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/privacypolicy">Privacy Policy</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/termscondition">Terms & Conditions</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/refundpolicy">Refund Policy</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/blogs">Blog</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Education Jobs</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="footer_links">
                                <ul>
                                        <li>
                                            <NavLink to="/about">About Us</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Plans</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/contact">Contact</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/privacypolicy">Privacy Policy</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/termscondition">Terms & Conditions</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/refundpolicy">Refund Policy</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/blogs">Blog</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Education Jobs</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="footer_social">
                                    <h4>Download the App</h4>
                                </div>
                                <div className="footer_app">
                                    <ul>
                                        <li>
                                            <img src="assets/images/g_pay.png" alt="google play" className="img-fluid" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="footer_cpright text-center">
                            <p>copyright | All right reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
