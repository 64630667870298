import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Similarjobs from "../common/Similarjobs";
import axios from "axios";
import swal from "sweetalert";

const JobDetail = () => {
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || "http://production";
const Token = localStorage.getItem('Token')
  const [data, setData] = useState({
    title: "",
    id: ""
  });
  let { id } = useParams();
  console.log("id", id);
  useEffect(() => {
    window.portal();
    detailData();
  }, [id]);
  


  const appledjob = (id) => {
    console.log("chek")
    const jobid = id;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Token}`
      }
    }

    const data = {
      "sop": "This is my test SOP which will be consider as only testing purposes."
    }
    axios.post(`${API_ENDPOINT}/api/jobs/${jobid}/applications`, data, headers)
    .then((res)=>{
      console.log(res)
      if (res.status == 200) {
        console.log(res)
        window.location.href = "/roleinterested";
      }
    })
      .catch((res) => {
          swal("error", res.response
          .data.message, "error", {
            buttons: false,
            timer: 2000,
          })
        
      })
      .then((err) => {
        
      })
  }


  const detailData = () => {
    axios.get(`${API_ENDPOINT}/api/jobs/${id}`).then((res) => {
      console.log(res.data);
      setData({ title: res.data.title,id:  res.data._id});
    });
  };
  return (
    <>
      <div className="user-landing">
        <div className="col-lg-9 mx-auto mb-5">
          <div className="user_wrap">
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <div className="user_right">
                  <div className="job_desc">
                    <div className="job_detail_card">
                      <div className="row">
                        <div className="col-lg-11 col-md-10 col-10">
                          <div className="jb_name">
                            <h3>{data.title}</h3>
                            <p>Delhi public school</p>
                          </div>
                        </div>
                        <div className="col-lg-1 col-md-2 col-2">
                          <div className="jb_title">dps</div>
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-lg-2 col-md-3 col-4">
                          <div className="jb_exp text-center">
                            <h4>
                              <span>
                                <i className="fa-solid fa-bag-shopping"></i>
                              </span>{" "}
                              &nbsp; 1 to 5 Years
                            </h4>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-5">
                          <div className="jb_prce text-center">
                            <h4>
                              <span>
                                <i className="fa-solid fa-indian-rupee-sign"></i>
                              </span>{" "}
                              &nbsp; 6,00,000-8,00,000
                            </h4>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-3">
                          <div className="jb_place text-center">
                            <h4>
                              <span>
                                <i className="fa-solid fa-location-dot"></i>
                              </span>{" "}
                              &nbsp; New Delhi
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="jb_desc">
                        <p>
                          We require faculty who can teach for GATE exam and
                          other competitive Architecture exams of post-graduate
                          level. Job involves teaching in offline/online
                          classNamees, development of study material and tests,
                          videos, etc. Good past GATE rank required.
                        </p>
                      </div>

                      <div className="row detail_wraps">
                        <div className="col-lg-2 col-md-3 col-4">
                          <div className="jb_time">
                            <p>
                              Posted: <b>5s ago</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-4">
                          <div className="jb_open">
                            <p>
                              Openings: <b>20</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-4">
                          <div className="jb_applicant">
                            <p>
                              Applicants: <b>55</b>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3 detail_job_save">
                        <div className="col-lg-2 col-md-3 col-4">
                          <div className="jb_apply_btn">
                            {Token?(<>
                              <button
                              type="button"
                              // data-bs-toggle="modal"
                              // data-bs-target="#exampleModal"
                              onClick={() => appledjob(data.id)}
                            >
                              apply now
                            </button>
                            </>):(<>
                            <NavLink to="/login/candidate">
                            <button
                              type="button"
                              // data-bs-toggle="modal"
                              // data-bs-target="#exampleModal"
                              
                            >
                              Login For Apply Now
                            </button>
                            </NavLink>
                              
                            </>)}
                           
                          </div>
                        </div>
                        <div className="col-lg-2 ps-0 col-md-3 col-4">
                          <div className="jb_save text-center">
                            <NavLink to="#">
                              <span>
                                <i className="fa-regular fa-bookmark"></i>
                              </span>{" "}
                              &nbsp; Save this job
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="job_detail_card_content">
                      <div className="detail_role">
                        <h3>Job description</h3>
                      </div>

                      <div className="detail_role">
                        <h4>Role & responsibilities</h4>
                        <p>
                          The role of Lecturer in the Business School will be
                          required to design, develop, and produce learning and
                          teaching material and deliver either across a range of
                          modules or within a subject area. Has responsibility
                          for ensuring that the students undertaking a specific
                          module or unit engage with the module, fulfil their
                          potential in terms of achievement of learning outcomes
                          and enjoy a high-quality student experience. In
                          addition, a lecturer may work more across a programme
                          to support students in their learning journey.
                        </p>
                      </div>

                      <div className="detail_role">
                        <h4>Specific duties and responsibilities</h4>
                        <p>
                          Teach a set number of sessions each week, including
                          lecturers, seminars, tutorials. Maintain attendance
                          registers for teaching sessions and worked with the
                          attendance team to ensure the accuracy of the
                          information being entered for students on digital
                          registers. Deliver/facilitate teaching sessions in
                          line with the approved programme and module
                          specifications Work in line with Digital provisions
                          including VLEs and MS Teams. Ensure that students are
                          engaging in their sessions Develop teaching materials
                          for modules. Update className notes and other
                          materials and ensure that the VLE is regularly
                          updated. Make suggestions through appropriate channels
                          how to improve student academic experience. Identify
                          the need for developing the content or structure of
                          modules with colleagues and make proposals on how this
                          should be achieved.
                        </p>
                      </div>

                      <div className="detail_role">
                        <h4>Assessment and Marking</h4>
                        <p>
                          Set assessments for students and ensure there is a
                          90+% submissions of assessments for all modules on
                          time. Support the production of assessments for
                          module/unit and ensure these are approved in
                          accordance with the relevant procedures. Participate
                          in standardisation and moderation meetings as
                          required.
                        </p>
                      </div>

                      <div className="detail_role">
                        <h4>Student support</h4>
                        <p>
                          Ensure any issues of student engagement or performance
                          are addressed, signposting students to specialist
                          College services as necessary. Field any pastoral
                          requirements students may have, signposting them to
                          relevant specialist College services as necessary.
                          Monitor the progress of individual students Learning
                          Support Plans, liaising with specialist services as
                          necessary
                        </p>
                      </div>

                      <div className="detail_role">
                        <h4>Preferred candidate profile</h4>
                        <p>
                          Ensure any issues of student engagement or performance
                          are addressed, signposting students to specialist
                          College services as necessary. Field any pastoral
                          requirements students may have, signposting them to
                          relevant specialist College services as necessary.
                          Monitor the progress of individual students Learning
                          Support Plans, liaising with specialist services as
                          necessary
                        </p>
                      </div>

                      <div className="content_mange">
                        <ul>
                          <li>
                            Role: <b>Assistant Professor / Lecturer</b>
                          </li>
                          <li>
                            Industry Type: <b>Education / Training</b>
                          </li>
                          <li>
                            Department: <b>Teaching & Training</b>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="job_detail_card_content">
                      <div className="detail_role mb-3">
                        <h3>About company</h3>
                      </div>
                      <div className="detail_role">
                        <h4>Higher Education School from UK</h4>
                        <p>
                          The role of Lecturer in the Business School will be
                          required to design, develop, and produce learning and
                          teaching material and deliver either across a range of
                          modules or within a subject area. Has responsibility
                          for ensuring that the students undertaking a specific
                          module or unit engage with the module, fulfil their
                          potential in terms of achievement of learning outcomes
                          and enjoy a high-quality student experience. In
                          addition, a lecturer may work more across a programme
                          to support students in their learning journey.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <Similarjobs />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <!-- <div className="modal-header2">

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div> --> */}
              <div className="modal-body add_detail_popup">
                <div className="popup_header mb-3">
                  <h2 className="pb-2">Online profiles</h2>
                  <p>
                    Add links to your social profiles (e.g. Linkedin profile,
                    Facebook profile, etc.)
                  </p>
                </div>
                <div className="popup mb-3">
                  <label className="form-label" htmlFor="">
                    Social profile
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-md"
                    placeholder="Start wring from here"
                  />
                </div>
                <div className="popup mb-3">
                  <label className="form-label" htmlFor="">
                    URL
                  </label>
                  <input type="text" className="form-control form-control-md" />
                </div>

                <div className="popup mb-3">
                  <label className="form-label" htmlFor="">
                    Description
                  </label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control form-control-md"
                  ></textarea>
                </div>
                <div className="popup_button">
                  <button type="button">save & continue</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetail;
