
import axios from "axios";


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || "http://production";
const token = localStorage.getItem("Token");

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
}

// first three caps letter
export function extractAndUpperCase(inputString) {
    let firstThreeChars = inputString.slice(0, 3);

    let result = firstThreeChars.toUpperCase();
    return result;
}




// get job list without token
export function getjoblist() {
    return axios.get(`${API_ENDPOINT}/api/job-list`).then((res) => {
        return res.data;
    });

}

//get list of data like 
// /industry
// /suggestion
// /keyskills
// /jobrole
// /rolecat
export function gettagslist(key) {
    if (key == 'department') {
        return axios.get(`${API_ENDPOINT}/api/list/${key}`).then((res) => {
            return res.data.data;
        });
    }
    if (key == 'industry') {
        return axios.get(`${API_ENDPOINT}/api/list/${key}`).then((res) => {
            return res.data.data;
        });
    }
    if (key == 'suggestion') {
        return axios.get(`${API_ENDPOINT}/api/list/${key}`).then((res) => {
            return res.data.data;
        });
    }
    if (key == 'keyskills') {
        return axios.get(`${API_ENDPOINT}/api/list/${key}`).then((res) => {
            return res.data.data;
        });
    }
    if (key == 'jobrole') {
        return axios.get(`${API_ENDPOINT}/api/list/${key}`).then((res) => {
            return res.data.data;
        });
    }
    if (key == 'rolecat') {
        return axios.get(`${API_ENDPOINT}/api/list/${key}`).then((res) => {
            return res.data.data;
        });
    }
}

export function getuser(){
   return axios.get(`${API_ENDPOINT}/api/user`,headers)
    .then((res)=>{ return res.data })
    .catch((err)=>{ console.log(err)})
}



