import React from 'react'
import { NavLink } from 'react-router-dom'

const Header = () => {
    return (
        <>
            <div className="col-lg-11 mx-auto">
                <nav className="navbar navbar-expand-lg header">
                    <div className="container-fluid">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="#">Companies</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="#">Schools</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/news">News</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/blogs">Blog</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="#">Post a Job</NavLink>
                                </li>

                                <li className="nav-item nav_mg">
                                    <NavLink className="nav-link nav_btn_border " to="/login/employer">Employer Login</NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink className="nav-link nav_btn" to="/login/candidate">Candidate Login</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Header
