import React from 'react'

const TermsCondition = () => {
  return (
    <section className="citiessecion py-5">
    <div className="container my-4">
      <div className="row text-center">
        <div className="heading mb-5">
          <h2>Privacy Policy</h2>
        </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
          <section>
        <p>By using [Your Company Name]'s services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our platform.</p>
    </section>

    <section>
        <h2>Account Registration</h2>
        <p>To access certain features of our platform, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and agree to notify us immediately of any unauthorized use of your account.</p>
    </section>

    <section>
        <h2>Use of Services</h2>
        <p>You agree to use our services only for lawful purposes and in accordance with these terms. You are prohibited from engaging in any activity that could harm, disrupt, or interfere with our platform or the services we provide.</p>
    </section>

    <section>
        <h2>Intellectual Property</h2>
        <p>All content and materials available on our platform, including but not limited to text, graphics, logos, images, and software, are the property of [Your Company Name] and are protected by applicable intellectual property laws.</p>
    </section>

    <section>
        <h2>Disclaimer of Warranties</h2>
        <p>Our platform is provided "as is" and "as available" without any warranties, expressed or implied. We do not guarantee the accuracy, completeness, or reliability of any content or materials on our platform.</p>
    </section>

    <section>
        <h2>Limitation of Liability</h2>
        <p>[Your Company Name] shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use our platform or services.</p>
    </section>

    <section>
        <h2>Changes to Terms</h2>
        <p>We reserve the right to modify or update these terms at any time. Any changes will be effective immediately upon posting on this page. Your continued use of our platform after the posting of changes constitutes your acceptance of such changes.</p>
    </section>

    <section>
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about our Terms and Conditions, please contact us at [your contact email address].</p>
    </section>
          </div>
        </div>
        </div>
        </section>
  )
}

export default TermsCondition