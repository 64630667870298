import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Similarjobs = (props) => {
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || "http://production";

  const [jbt, setJbt] = useState([]);
  const [smt, setsmt] = useState("");

  const getData = () => {
    axios
      .get(`${API_ENDPOINT}/api/job-list`)
      .then((res) => {
        setJbt(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setsmt("owl-carousel");
      window.portal();
    }, 1000);
  }, [jbt]);

  function extractAndUpperCase(inputString) {
    let firstThreeChars = inputString.slice(0, 3);

    let result = firstThreeChars.toUpperCase();
    return result;
  }
  return (
    <>
      <div class="jb_detail_section">
        <div class="user_job">
          <div class="job_card_title">
            <h3>
              Similar jobs <NavLink to="/jobs/similer">View All</NavLink>
            </h3>
          </div>
          <div class="job_card_wrapper " id="demos">
            {jbt.lenght <= 0 ? (
              <></>
            ) : (
              <>
                <div class={`${smt} jobs`}>
                  {jbt.slice(0,10).map((item, index) => {
                    return (
                      <div key={index} class="job_card">
                        <div class="jb_wrapper">
                          <div class="jb_card_content">
                            <div class="row">
                              <div class="col-lg-3">
                                <div class="jb_logo">
                                  {extractAndUpperCase(item.recruiter.name)}
                                </div>
                              </div>
                              <div class="col-lg-9">
                                <div class="jb_timing text-end">
                                  <h6>2d ago</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="jb_heading">
                            <NavLink to={`/jobdetail/${item._id}`}>
                              <h3>{item.title}</h3>
                            </NavLink>
                            <p>Office Administrator lines...</p>
                            <span>New Delhi</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>

        <div class="job_ad jobsColor text-center">
          <div class="row">
            <div class="col-lg-12 text-center">
              <div class="ad_image text-center">
                <img
                  src="/assets/images/folder.png"
                  alt="folder image"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="ad_content">
                <h3>
                  Highlight your application and stand out from rest of the
                  applicants
                </h3>
                <p>Paid service by Falana</p>
                <div class="ad_btn">
                  <a href="#">Know More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Similarjobs;
