import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import {getuser, getjoblist} from '../components/Global'

const Userlanding = () => {
  const token = localStorage.getItem("Token");
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || "http://production";

    const [userdetails,setuserdetails]= useState(
      {name: "loading...",
      prefrences: [{resumeheadline: "loading...."}]
    });
  
    const [percentageprofile,setpercentage]=useState(0);
  const [jbt, setJbt] = useState([]);
  const [smt, setsmt] = useState("");

 const  checkprofilepercentage=(userData)=>{
  let percentage = 0;
    // Check conditions and update percentage accordingly
if (userData.education && userData.education.length > 0) {
  percentage += 10;
}

if (userData.employement && userData.employement.length > 0) {
  percentage += 10;
}

if (userData.skills && userData.skills.length > 0) {
  percentage += 20;
}

if (userData.contactNumber) {
  percentage += 10;
}

if (userData.workstatus) {
  percentage += 10;
}

if (userData.resume) {
  percentage += 20;
}

if (userData.profile) {
  percentage += 10;
}

if (userData.prefrences && userData.prefrences.length > 0) {
  percentage += 10;
}

setpercentage(percentage);
  }
  useEffect(() => {
    window.portal();
    getjoblist()
    .then((data)=>{
      setJbt(data);
    })
    getuser().then((result)=>{
      setuserdetails(result)
      checkprofilepercentage(result);
      
     
    })
   
   
  }, []);




  useEffect(() => {
    setTimeout(() => {
      setsmt("owl-carousel");
      window.portal();
    }, 1000);
  }, [jbt]);
  function extractAndUpperCase(inputString) {
    let firstThreeChars = inputString.slice(0, 3);

    let result = firstThreeChars.toUpperCase();
    return result;
  }



  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 4;
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = jbt.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(jbt.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % jbt.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <>
      <div className="user-landing-card">
        <div className="col-lg-9 mx-auto mb-5 pb-5">
          <div className="user_wrap">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="user_profile">
                  <div className="user_img text-center">
                    <img
                      src="assets/images/madhav.png"
                      alt="user"
                      className="img-fluid"
                    />
                  </div>
                  <div className="user_name text-center">
                    <h2>{userdetails.name}</h2>
                    <h6>{userdetails.prefrences[0].resumeheadline}</h6>
                    <p>Last updated 7d ago</p>
                  </div>
                  <div className="prfl_comp text-center">
                    <h3>Profile completeness</h3>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example with label"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar" style={{ width: percentageprofile+"%" }}>
                      {percentageprofile}%
                      </div>
                    </div>
                  </div>
                  <div className="prfle_complete text-center pt-3">
                    <NavLink to="/profile">
                      <button type="button">Complete profile now</button>
                    </NavLink>
                  </div>

                  <div className="user_links">
                    <ul>
                      <li>
                        <NavLink to="#"> my home</NavLink>
                      </li>
                      <li>
                        <NavLink to="/jobs/all"> jobs</NavLink>
                      </li>
                      <li>
                        <NavLink to="#"> companies</NavLink>
                      </li>
                      <li>
                        <NavLink to="/blogs"> blogs</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="user_right">
                  <div className="user_job">
                    <div className="job_card_title">
                      <h3>
                        Recommended jobs for you{" "}
                        <NavLink to="/jobs/recommended">View All</NavLink>
                      </h3>
                    </div>
                    <div className="job_card_wrapper " id="demos">
                      <div className={`${smt} jbt`}>
                        {jbt.slice(0,20).map((item, index) => {
                          return (
                            <div key={index} className="job_card">
                              <div className="jb_wrapper">
                                <div className="jb_card_content">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3">
                                      <div className="jb_logo">
                                        {extractAndUpperCase(
                                          item.recruiter.name
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-lg-9 col-md-9">
                                      <div className="jb_timing text-end">
                                        <h6>2d ago</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jb_heading">
                                  <h3>{item.title}</h3>
                                  <p>Office Administrator lines...</p>
                                  <span>New Delhi</span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="job_ad">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="ad_content">
                          <h3>
                            Highlight your application and stand out from rest
                            of the applicants
                          </h3>
                          <p>Paid service by Falana</p>
                          <div className="ad_btn">
                            <NavLink to="#">Know More</NavLink>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 offset-lg-2 col-md-4">
                        <div className="ad_image text-center">
                          <img
                            src="assets/images/17099837681610058221-512.png"
                            alt="folder"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="job_desc">
                    <ul>
                      {currentItems.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="row">
                              <div className="col-lg-1 col-md-2 col-2">
                                <div className="jb_title">
                                  {" "}
                                  {extractAndUpperCase(item.recruiter.name)}
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-7 col-7">
                                <div className="jb_name">
                                  <NavLink to={`/jobdetail/${item._id}`}>
                                    <h3>{item.title}</h3>
                                  </NavLink>
                                  <p>Delhi public school</p>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-3 col-3">
                                <div className="jb_save text-center">
                                  <NavLink to="#">
                                    <span>
                                      <i className="fa-regular fa-bookmark"></i>
                                    </span>{" "}
                                    &nbsp; Save this job
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                            <div className="row pt-4">
                              <div className="col-lg-2 col-md-3 col-4">
                                <div className="jb_exp text-center">
                                  <h4>
                                    <span>
                                      <i className="fa-solid fa-bag-shopping"></i>
                                    </span>{" "}
                                    &nbsp; 1 to 5 Years
                                  </h4>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-5">
                                <div className="jb_prce text-center">
                                  <h4>
                                    <span>
                                      <i className="fa-solid fa-indian-rupee-sign"></i>
                                    </span>{" "}
                                    &nbsp; {item.salary}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-3 col-3">
                                <div className="jb_place text-center">
                                  <h4>
                                    <span>
                                      <i className="fa-solid fa-location-dot"></i>
                                    </span>{" "}
                                    &nbsp; New Delhi
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className="jb_desc">
                              <p>
                                We require faculty who can teach for GATE exam
                                and other competitive Architecture exams of
                                post-graduate level. Job involves teaching in
                                offline/online classNamees, development of study
                                material and tests, videos, etc. Good past GATE
                                rank required.
                              </p>
                            </div>
                            <div className="jb_time">
                              <p>5s ago</p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div class="mypaginate">
              <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Userlanding;
