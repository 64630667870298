import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const HelpCenter = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
   <>
    <section className="citiessecion py-5">
        <div className="container my-4">
          <div className="row text-center">
            <div className="heading mb-5">
              <h2>Help Center</h2>
            </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
              <section>
        <h2>Frequently Asked Questions (FAQs)</h2>
        <ul>
            <li><strong>How do I create an account?</strong></li>
            <li><strong>What should I do if I forget my password?</strong></li>
            <li><strong>How can I update my profile information?</strong></li>
            <li><strong>How do I search for jobs on your platform?</strong></li>
            <li><strong>What is the application process?</strong></li>
        </ul>
    </section>

    <section>
        <h2>Contact Information</h2>
        <p>If you need further assistance, please don't hesitate to contact our support team:</p>
        <ul>
            <li><strong>Email:</strong> support@[yourcompanyname].com</li>
            <li><strong>Phone:</strong> [Your support phone number]</li>
        </ul>
    </section>

    <section>
        <h2>Getting Started</h2>
        <p>For a quick guide on getting started with our platform, please refer to our <a href="#getting-started-guide">Getting Started Guide</a>.</p>
    </section>

    <section>
        <h2>Terms of Service</h2>
        <p>For information regarding our terms and conditions, please review our <a href="#terms-of-service">Terms of Service</a>.</p>
    </section>

    <section>
        <h2>Privacy Policy</h2>
        <p>Learn about how we collect, use, and protect your data by reviewing our <a href="#privacy-policy">Privacy Policy</a>.</p>
    </section>

    <section>
        <h2>Feedback</h2>
        <p>We value your feedback! If you have suggestions or comments about our platform, please let us know through our <a href="#feedback-form">Feedback Form</a>.</p>
    </section>
              </div>
            </div>
            </div>
            </section>
   </>
  )
}

export default HelpCenter