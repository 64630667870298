import React from 'react'

const About = () => {
  return (
    <>
    <section className="citiessecion py-5">
        <div className="container my-4">
          <div className="row text-center">
            <div className="heading mb-5">
              <h2>About US</h2>
             
            </div>

           
          </div>
          <div className='row'>
            <div className='col-md-12'>
              
    <section>
        <h2>Who We Are</h2>
        <p>At Job Portal Name, we are not just a job portal; we are your partners in career advancement. Our team is driven by a shared commitment to empower individuals and businesses by fostering meaningful connections. We believe that the right job has the power to transform lives and drive organizational success.</p>
    </section>

    <section>
        <h2>Our Mission</h2>
        <p>Our mission is to bridge the gap between talent and opportunity, providing a seamless and user-friendly platform that simplifies the job search and recruitment process. We are dedicated to creating an ecosystem where individuals can explore, grow, and thrive in their careers while helping companies build exceptional teams.</p>
    </section>

    <section>
        <h2>What Sets Us Apart</h2>
        <ul>
            <li><strong>Comprehensive Job Listings:</strong> We offer an extensive range of job listings across various industries, ensuring that you have access to a diverse array of opportunities.</li>
            <li><strong>User-Friendly Interface:</strong> Our platform is designed with you in mind. Navigating through job listings, creating profiles, and managing applications is easy and intuitive.</li>
            <li><strong>Advanced Matching Algorithms:</strong> We leverage cutting-edge technology to match candidates with the most relevant job opportunities, saving time and increasing the likelihood of finding the perfect fit.</li>
            <li><strong>Career Resources:</strong> In addition to job listings, we provide valuable resources such as career advice, resume tips, and interview guidance to support your professional development.</li>
            <li><strong>Dedicated Support:</strong> Our customer support team is committed to ensuring a smooth experience for both job seekers and employers. Whether you have questions about using our platform or need assistance with your job search, we are here to help.</li>
        </ul>
    </section>

    <section>
        <h2>For Employers</h2>
        <p>Job Portal Name is not just about helping individuals find the right job; it's also about assisting companies in building high-performing teams. We offer a range of recruitment solutions, from job postings to advanced candidate screening, to streamline your hiring process.</p>
    </section>

    <section>
        <h2>Join Job Portal Name Today</h2>
        <p>Embark on a journey of professional growth with Job Portal Name. Whether you are a job seeker ready to take the next step in your career or an employer looking for top-tier talent, we invite you to explore the possibilities on our platform.</p>
        <p>At Job Portal Name, your success is our priority. Let's shape the future together. Welcome to a world of opportunities!</p>
    </section>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About