import React, { useEffect, useState } from "react";
import MultiSelect from "../components/MultiSelect";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {gettagslist} from '../components/Global';
import { Country, State, City }  from 'country-state-city';


// console.log(City.getCitiesOfCountry('IN'));


const Multistepform = () => {

  const Token = localStorage.getItem('Token');
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || "http://production";
  const [data, setData] = useState({
    working: "",
    exp_year: "",
    exp_month: "",
    company: "",
    jobTitle: "",
    city: "",
    state: "",
    noticePeriod: "",
    industry: "",
    department: "",
    roleCategory: "",
    jobRole: "",
  });
  const [data2, setData2] = useState({
    qualification: "",
    course: "",
    specialization: "",
    university: "",
    coursetype: "",
    startingyear: "",
    endingyear: ""
  });

  const [data3, setData3] = useState({
    resume: "",
    gender: "",
    salery: ""
  });

  //multiselect for suggstions
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState([]);
  const [selectedOption3, setSelectedOption3] = useState([]);

  const [options,setoption] = useState([]);
  const [options2,setoption2] = useState([]);
  const [options3,setoption3] = useState([]);

  const  [industrylist,setindustrylist] =useState([]);
  const  [jobrolelist,setjobrolelist] =useState([]);
  const  [rolecatlist,setrolecatlist] =useState([]);
  const  [departmentlist,setdepartmentlist] =useState([]);

  useEffect(() => {
    window.portal();
    gettagslist('keyskills').then((result)=>{
   const datakeysills =[]
      for(let i = 0 ;i<result.length;i++){
        datakeysills.push({ value: result[i].name, label: result[i].name })
      }
      setoption(datakeysills);
    });
    gettagslist('suggestion').then((result)=>{
      const datasuggestion =[]
      for(let i = 0 ;i<result.length;i++){
        datasuggestion.push({ value: result[i].name, label: result[i].name })
      }
      setoption2(datasuggestion)
    });
    gettagslist('industry').then((result)=>{
      setindustrylist(result)
    });
    gettagslist('jobrole').then((result)=>{
    setjobrolelist(result)
    });
    gettagslist('rolecat').then((result)=>{
      console.log(result)
      setrolecatlist(result)
    });
    gettagslist('department').then((result)=>{
      setdepartmentlist(result)
    });
    const mylocations =[];
   const templocation = City.getCitiesOfCountry('IN')

    for(let x=0;x<templocation.length;x++){
      
      mylocations.push({ value: templocation[x].name, label: templocation[x].name })
    }
    setoption3(mylocations);
  },[]);

  const [date, setDate] = useState("dd-mm-yyyy");
  const [date2, setDate2] = useState("dd-mm-yyyy");
  const [date3, setDate3] = useState("dd-mm-yyyy");
  const [date4, setDate4] = useState("dd-mm-yyyy");

  const handleDate = (e) => {
    setDate(e.target.value);
    console.log(e.target.value);
  };

  const handleDate2 = (e) => {
    setDate2(e.target.value);
    console.log(e.target.value);
  };

  const handleDate3 = (e) => {
    setDate3(e.target.value);
    console.log(e.target.value);
  };

  const handleDate4 = (e) => {
    setDate4(e.target.value);
    console.log(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
   console.log(data)
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setData2((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setData3((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const [formValid, setFormValid] = useState(false);

  const validateForm = (values) => {
    for (const field in values) {
      if (!values[field].trim()) {
        return false;
      }
    }
    return true;
  };

  
  const handleNext = (key) => {

if(key== 'addemployement'){
 //add employementdetails

 const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${Token}`
  }
}
  
const suggestiondata = [];
const keyskillsdata = [];
for(var i =0;i<selectedOption.length;i++)
{
suggestiondata.push(selectedOption[i].value)
}
for(var j =0;j<selectedOption2.length;j++)
{
keyskillsdata.push(selectedOption2[j].value)
}

  const payloadaddemployement = {
    working: data.working,
    ExperienceYear: data.exp_year,
    ExperienceMonth: data.exp_month,
    company: data.company,
    jobTitle: data.jobTitle,
    city: data.city,
    state: data.state,
    WorkingSciencefrom: date,
    WorkingScienceto: date2,
    Notice: data.noticePeriod,
    myskills: keyskillsdata,
    suggestions: suggestiondata,
    industry: data.industry,
    department: data.department,
    rolecat: data.roleCategory,
    role: data.jobRole
  }
   console.log(payloadaddemployement)
      axios.post(`${API_ENDPOINT}/api/addemployment`,payloadaddemployement,headers)
      .then((res)=>{
        if(res.status==200){
         
          toast.success(res.data.message)
        }
      })
      .catch((err)=>{
  console.log(err)
      })

}
if(key=='addedu'){
// // add educationaal details

const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${Token}`
  }
}
console.log(data2)
const payloadaddeducation = {
  qualification: data2.qualification,
course: data2.course,
specialization: data2.specialization,
iname: data2.university,
coursetype: data2.coursetype,
startingyear: data2.startingyear,
passingyear: data2.endingyear
}
console.log(data2)
axios.post(`${API_ENDPOINT}/api/addeducation`,data2,headers)
.then((res)=>{
  if(res.status==200){
    toast.success(res.data.message)
  }
})
.catch((err)=>{
console.log(err)
})

}
if(key=='preference'){

  const locationsdata = [];
for(var i =0;i<selectedOption3.length;i++)
{
  locationsdata.push(selectedOption3[i].value)
}
const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${Token}`
  }
}
  const payloadaddprefrence ={
    resumeheadline: data3.resume,
    preferedlocation: locationsdata,
    preferedsalery: data3.salery,
    gender: data3.gender
  }

  console.log(payloadaddprefrence)
  axios.post(`${API_ENDPOINT}/api/addprefrences`,payloadaddprefrence,headers)
.then((res)=>{
  if(res.status==200){
    toast.success(res.data.message)
    window.location.href = '/'
  }
})
.catch((err)=>{
console.log(err)
})
}

    
  };
  const handleSubmit = () => {
    console.log(data3);
  };
  const returnsomthing = (check)=>{
    console.log(check)
    return check==true?('card2'):('sd')
  }

  return (
    <>
      <div className="user-landing_register">
        <div className="bg_img"></div>
        <div className="col-lg-9 mx-auto col-12">
          <div className="row flex-md-row px-3 mt-4 flex-column-reverse">
            <div className="col-lg-8 col-md-8 col-12">





              <div className="card2 first-screen show reg_frm">
                <div className="row">
                  <div className="col-lg-11">
                    <div className="reg_title">
                      <h2>Add your employment details</h2>
                      <p>
                        We will send you relevant job application and update on
                        this email
                      </p>
                    </div>
                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Are you working now
                      </label>
                      <div className="row">
                        <div className="col-lg-1 col-md-2 col-4">
                          <div className="emp_yes">
                            <input
                              type="radio"
                              id="yes"
                              value="yes"
                              name="working"
                              onChange={handleChange}
                            />
                            <label className="form-label" htmlFor="yes">
                              yes
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-3 ps-0">
                          <div className="emp_no ">
                            <input
                              type="radio"
                              id="no"
                              value="no"
                              name="working"
                              onChange={handleChange}
                            />
                            <label className="form-label" htmlFor="no">
                              no
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Total Work Exp.
                      </label>
                      <div className="row">
                        <div className="col-lg-5 col-6"> 
                          <div className="emp_yes">
                            <select
                              className="form-select"
                              name="exp_year"
                              id=""
                              onChange={handleChange}
                            >
                              <option value="year">Year</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-5 col-6">
                          <div className="emp_no">
                            <select
                              className="form-select"
                              name="exp_month"
                              id=""
                              onChange={handleChange}
                            >
                              <option value="month">Month</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="reg_field inpWidth">
                      <label className="form-label" htmlFor="">
                        Company
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="company"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="reg_field inpWidth">
                      <label className="form-label" htmlFor="">
                        Job title
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="jobTitle"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="reg_field">
                      <div className="row">
                        <div className="col-lg-5 col-6">
                          <label className="form-label" htmlFor="">
                            Current City
                          </label>
                          <div className="emp_yes">
                            <select
                              className="form-select"
                              name="city"
                              id=""
                              onChange={handleChange}
                            >
                              <option value="select">Select</option>
                              <option value="faridabad">Faridabad</option>
                              <option value="delhi">Delhi</option>
                              <option value="Gurgaon">Gurgaon</option>
                              <option value="Noida">Noida</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-5 col-6">
                          <label className="form-label" htmlFor="">
                            State
                          </label>
                          <div className="emp_yes">
                            <select
                              className="form-select"
                              name="state"
                              id=""
                              onChange={handleChange}
                            >
                              <option value="select">Select</option>
                              <option value="haryana">Haryana</option>
                              <option value="uttar pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="punjab">Punjab</option>
                              <option value="rajasthan">Rajasthan</option>
                              <option value="bihar">Bihar</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="work_wrapper">
                      <label className="form-label" htmlFor="date">
                        Working since
                      </label>
                      <div className="row">
                        <div className="col-lg-5 col-6">
                          <div class="input-group2 date">
                            <input
                              type="date"
                              class="form-control"
                              id="date"
                              name="fromDate"
                              value={date}
                              onChange={handleDate}
                            />
                            <span class="input-group-append"></span>
                          </div>
                        </div>

                        <div className="col-lg-5 col-6">
                          <div className="work_select date2">
                            <input
                              type="date"
                              className="form-control"
                              id="date2"
                              name="toDate"
                              value={date2}
                              onChange={handleDate2}
                            />
                            <span class="input-group-append"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="reg_field w94">
                      <label className="form-label" htmlFor="">
                        Notice period
                      </label>
                      <div className="col-lg-5 col-12">
                        <select
                          className="form-select"
                          name="noticePeriod"
                          id=""
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </div>
                    </div>

                    <div className="reg_field reg_job">
                      <label className="form-label" htmlFor="">
                        Key Skills
                      </label>
                      <div className="suggest_type">
                        <div class="select-sample">
                          <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            isMulti="true"
                          />
                        </div>

                      </div>
                    </div>

                    <div className="reg_field reg_job">
                      <label className="form-label" htmlFor="">
                        Suggestions
                      </label>
                      <div className="suggest_type">
                        <div class="select-sample">
                          <Select
                            defaultValue={selectedOption2}
                            onChange={setSelectedOption2}
                            options={options2}
                            isMulti="true"
                          />
                        </div>

                      </div>
                    </div>
                    {/* <label className="form-label" for="">
                            skill name
                          </label>
                          <label className="form-label" for="">
                            skill name
                          </label>
                          <label className="form-label" for="">
                            skill name
                          </label>
                          <label className="form-label" for="">
                            skill name
                          </label> */}
                    <div className="reg_field reg_job">
                      <label className="form-label" htmlFor="">
                        Industry
                      </label>

                      <select
                        className="form-select"
                        tabIndex="8"
                        name="industry"
                        onChange={handleChange}
                      >
                        {
                          industrylist.map((data,index)=>{
                            return (
 <option key={index} value={data.name}>{data.name}</option> 
                            )
                          })
                        }
                      </select>
                    </div>

                    <div className="reg_field reg_job">
                      <label className="form-label" htmlFor="">
                        Department
                      </label>

                      <select
                        className=" form-select"
                        tabIndex="8"
                        name="department"
                        onChange={handleChange}
                      >{
                        departmentlist.map((data,index)=>{
                             return(
                              <>
                              <option key={index} value={data.name}>{data.name}</option>
                              </>
                             )
                        })
                      } 
                      </select>
                    </div>

                    <div className="reg_field reg_job">
                      <label className="form-label" htmlFor="">
                        Role category
                      </label>

                      <select
                        className=" form-select"
                        tabIndex="8"
                        name="roleCategory"
                        onChange={handleChange}
                      >
                        {
                          rolecatlist.map((data,index)=>{
                            return(<option key={index} value={data.name}>{data.name}</option>)
                            
                          })
                        }
                      </select>
                    </div>

                    <div className="reg_field reg_job inpWidth">
                      <label className="form-label" htmlFor="">
                        Job Role
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="jobRole"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
{
  validateForm(data)?(<>
  <div className="next_btn">
                    <button type="button" onClick={()=>handleNext('addemployement')}>
                      next step d
                    </button>
                  </div>
                  {/* {window.portal()} */}
  </>):(<>
    <div className="next_btn" style={{ pointerEvents: "none"}}>
                    <button>
                      next step
                    </button>
                  </div>
    
  </>)
}
   {/* <div className="next_btn">
                    <button type="button" onClick={()=>handleNext('addemployement')}>
                      next step
                    </button>
                  </div> */}
                  
                </div>
              </div>







              <div className="card2 reg_frm">
                <div className="row">
                  <div className="col-lg-9 col-12">
                    <div className="reg_title">
                      <h2>Add your education details</h2>
                      <p>
                        We will send you relevant job application and update on
                        this email
                      </p>
                    </div>

                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Highest qualification
                      </label>
                      <div className="emp_yes">
                        <select
                          className="form-select"
                          name="qualification"
                          id=""
                          onChange={handleChange1}
                        >
                          <option value="select">Select</option>
                          <option value="PostGraduation">
                            Post Graduation
                          </option>
                          <option value="Graduation">Graduation</option>
                          <option value="Diploma">Diploma</option>
                          <option value="ITI">ITI</option>
                          <option value="12th">12th</option>
                        </select>
                      </div>
                    </div>

                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Course
                      </label>
                      <div className="emp_yes">
                        <select
                          className="form-select"
                          name="course"
                          id=""
                          onChange={handleChange1}
                        >
                          <option value="select">Select</option>
                          <option value="mtech">M.tech</option>
                          <option value="mca">MCA</option>
                          <option value="btech">B.tech</option>
                          <option value="bca">BCA</option>
                          <option value="ba">BA</option>
                        </select>
                      </div>
                    </div>

                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Specialization
                      </label>
                      <div className="emp_yes">
                        <select
                          className="form-select"
                          name="specialization"
                          id=""
                          onChange={handleChange1}
                        >
                          <option value="select">Select</option>
                          <option value="mechanical">Mechanical</option>
                          <option value="electrical">Electrical</option>
                          <option value="computer science">
                            Computer Science
                          </option>
                          <option value="information technology">
                            Information Technology
                          </option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>

                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        University/Institute
                      </label>
                      <div className="emp_yes">
                        <select
                          className="form-select"
                          name="university"
                          id=""
                          onChange={handleChange1}
                        >
                          <option value="select">Select</option>
                          <option value="mdu">MDU</option>
                          <option value="lpu">LPU</option>
                          <option value="ku">KU</option>
                        </select>
                      </div>
                    </div>

                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Course type
                      </label>
                      <div className="emp_yes">
                        <select
                          className="form-select"
                          name="coursetype"
                          id=""
                          
                          onChange={handleChange1}
                        >
                          <option value="parttime">PartTime</option>
                          <option value="hourly">Hourly Basis</option>
                          <option value="fulltime">Fulltime</option>
                          <option value="distance">Distance</option>
                        </select>
                      </div>
                    </div>

                    
                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                      Starting year
                      </label>
                      <div className="emp_yes">
                        <select
                          className="form-select"
                          name="startingyear"
                          id=""
                          
                          onChange={handleChange1}
                        >
                          <option value="2001">2001</option>
                          <option value="2001">2002</option>
                          <option value="2001">2003</option>
                          <option value="2001">2004</option>
                          <option value="2001">2005</option>
                          <option value="2001">2006</option>
                          <option value="2001">2007</option>
                          <option value="2001">2008</option>
                          <option value="2001">2009</option>
                        </select>
                      </div>
                    </div>
                    

                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                      Ending year
                      </label>
                      <div className="emp_yes">
                        <select
                          className="form-select"
                          name="endingyear"
                          id=""
                         
                          onChange={handleChange1}
                        >
                          <option value="2001">2001</option>
                          <option value="2001">2002</option>
                          <option value="2001">2001</option>
                          <option value="2001">2002</option>
                          <option value="2001">2001</option>
                          <option value="2001">2002</option>
                          <option value="2001">2001</option>
                          <option value="2001">2002</option>
                          <option value="2001">2001</option>
                          <option value="2001">2002</option>

                        </select>
                      </div>
                    </div>
                  </div>

                  {
  validateForm(data2)?(<>
  
  <div className="next_btn">
                    <button type="button" onClick={()=>handleNext('addedu')}>
                      next step
                    </button>
                  </div>
                  
  </>):(<>
    <div className="next_btn" style={{ pointerEvents: "none"}} >
                    <button>
                      next step
                    </button>
                  </div>
    
  </>)
}
                </div>
              </div>







              <div className="card2 reg_frm space_bw">
                <div className="row">
                  <div className="col-lg-9 col-12">
                    <div className="reg_title">
                      <h2>Add headline and prefrences</h2>
                      <p>
                        We will send you relevant job application and update on
                        this email
                      </p>
                    </div>

                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Resume headline
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Start writing from here"
                        name="resume"
                        id=""
                        cols="30"
                        rows="5"
                        onChange={handleChange2}
                      ></textarea>
                    </div>

                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Preferred work location
                      </label>
                      <div className="suggest_type work_sample">
                      <div class="select-sample">
                          <Select
                            defaultValue={selectedOption3}
                            onChange={setSelectedOption3}
                            options={options3}
                            isMulti="true"
                          />
                        </div>

                      </div>
                    </div>
                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Preferred Salery
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="salery"
                        onChange={handleChange2}
                      />
                    </div>
                   
                    <div className="reg_field">
                      <label className="form-label" htmlFor="">
                        Gender
                      </label>

                      <div className="row">
                        <div className="col-lg-1 col-md-2 col-4">
                          <div className="emp_yes">
                            <input
                              type="radio"
                              id="Male"
                              name="gender"
                              value="Male"
                              onChange={handleChange2}
                            />
                            <label className="form-label" htmlFor="Male">
                              Male
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-2 col-3">
                          <div className="emp_no ">
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="Female"
                              onChange={handleChange2}
                            />
                            <label className="form-label" htmlFor="female">
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
  validateForm(date3)?(<>
  <div className="next_btn">
                    <button type="button" onClick={()=>handleNext('preference')}>
                      next step
                    </button>
                  </div>
  </>):(<>
    <div className="next_btn" style={{ pointerEvents: "none"}}>
                    <button>
                      next step
                    </button>
                  </div>
    
  </>)
}
                </div>
              </div>







              <div className="card2 reg_frm">
                <div className="row px-3 mt-2 mb-4 text-center">
                  <h2 className="col-12 text-danger">
                    <strong>Success !</strong>
                  </h2>
                  <div className="col-12 text-center">
                    <img
                      className="tick"
                      src="https://i.imgur.com/WDI0da4.gif"
                      alt="success"
                    />
                  </div>
                  <h6 className="col-12 mt-2">
                    <i>...Enjoy COOKIES...</i>
                  </h6>
                </div>
              </div>
            </div>







            <div className="col-lg-4 col-md-4 col-12">
              <div className="card1">
                <ul id="progressbar" className="text-center">
                  <li className="active step0">
                    <div className="time_text">
                      <h6>Basic detail</h6>
                    </div>
                  </li>
                  <li className="step0">
                    <div className="time_text">
                      <h6>Employement</h6>
                      <p>
                        Add your experience to your success story, and mention
                        about it
                      </p>
                    </div>
                  </li>
                  <li className="step0">
                    <div className="time_text">
                      <h6>Education</h6>
                    </div>
                  </li>
                  <li className="step0">
                    <div className="time_text">
                      <h6>Final Step</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
rtl={false}
pauseOnHover={true}
theme="light"
/>
    </>
  );
};
export default Multistepform;
