import React, { useEffect, useState } from "react";
import Similarjobs from "../common/Similarjobs";
import { NavLink, useParams,useLocation } from "react-router-dom";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import {extractAndUpperCase} from '../components/Global'

function Jobs() {
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || "http://production";
  const [jbt, setJbt] = useState([]);
 const [pagetitle,setagetitle]=useState('')
  let { param } = useParams();
const token = localStorage.getItem('Token')
  // geting search params
  const location = useLocation();
  const { search } = location;
  const params2 = new URLSearchParams(search);
  const city = params2.get('city');
  const query = params2.get('query');
  const experience = params2.get('experience');
  

  const searchjobs = (city,query,experience)=>{
    const headers = {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  }
    axios.get(`${API_ENDPOINT}/api/jobs?q=${query}`,headers)
    .then((res)=>{
      console.log(res)
      setJbt(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

 

  const checkcomponent = ()=>{
    if(param=="all")
    {
      getjobs();
      setagetitle("All")
    }
    if(param=="similer"){
      getjobs();
      setagetitle("Similer")
    }
    if(param=="recommended"){
      getjobs();
      setagetitle("Recommended")
    }
  }
  useEffect(() => {
    window.portal();
    
  if(city||query||experience){
    searchjobs(city,query,experience);
  }
  else{
    checkcomponent();
  }
   
    
  }, [param,city,experience,query]);


  const getjobs = () => {
    axios
      .get(`${API_ENDPOINT}/api/job-list`)
      .then((res) => {
        console.log(res);
        setJbt(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  

  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 4;
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = jbt.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(jbt.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % jbt.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <div class="user-landing">
      <div class="col-lg-9 mx-auto mb-5">
        <div class="user_wrap">
          <div class="row">
            <div class="col-lg-8">
              <div class="job_card_title mb-4">
                <h3>{pagetitle} jobs for you</h3>
              </div>
              <div class="job_desc">
                <ul>
                  {currentItems.map((item, index) => {
                    return (
                      <li key={index}>
                        <div className="row">
                          <div className="col-lg-1 col-md-2 col-2">
                            <div className="jb_title">
                              {extractAndUpperCase(item.recruiter.name)}
                            </div>
                          </div>
                          <div className="col-lg-9 col-md-7 col-7">
                            <div className="jb_name">
                              <NavLink to={`/jobdetail/${item._id}`}> 
                                <h3>{item.title}</h3>
                              </NavLink>
                              <p>Delhi public school</p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-3 col-3">
                            <div className="jb_save text-center">
                              <NavLink to="#">
                                <span>
                                  <i className="fa-regular fa-bookmark"></i>
                                </span>{" "}
                                &nbsp; Save this job
                              </NavLink>
                            </div>
                          </div>
                        </div>
                        <div className="row pt-4">
                          <div className="col-lg-2 col-md-3 col-4">
                            <div className="jb_exp text-center">
                              <h4>
                                <span>
                                  <i className="fa-solid fa-bag-shopping"></i>
                                </span>{" "}
                                &nbsp; 1 to 5 Years
                              </h4>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4 col-5">
                            <div className="jb_prce text-center">
                              <h4>
                                <span>
                                  <i className="fa-solid fa-indian-rupee-sign"></i>
                                </span>{" "}
                                &nbsp; {item.salary}
                              </h4>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-3 col-3">
                            <div className="jb_place text-center">
                              <h4>
                                <span>
                                  <i className="fa-solid fa-location-dot"></i>
                                </span>{" "}
                                &nbsp; New Delhi
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="jb_desc">
                          <p>
                            We require faculty who can teach for GATE exam and
                            other competitive Architecture exams of
                            post-graduate level. Job involves teaching in
                            offline/online classNamees, development of study
                            material and tests, videos, etc. Good past GATE rank
                            required.
                          </p>
                        </div>
                        <div className="jb_time">
                          <p>5s ago</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
             

              </div>
              <div class="mypaginate">
              <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
              </div>
          
            </div>
            <div class="col-lg-4">
              <Similarjobs data={jbt}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jobs;
