import { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Otp from "./pages/Otp";
import JobDetail from "./pages/JobDetail";
import Multistepform from "./pages/Multistepform";
import Userprofile from "./pages/Userprofile";
import Userlanding from "./pages/Userlanding";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Jobheader from "./common/Jobheader";
import News from "./resources/News";
import Login from "./pages/Login";
import Jobs from "./pages/Jobs";
import Rolesinterested from "./pages/Roleinterested";
import About from "./resources/About";
import Blogs from "./resources/Blogs";
import Contact from "./resources/Contact";
import HelpCenter from "./resources/HelpCenter";
import PrivacyPolicy from "./resources/PrivacyPolicy";
import RefundPolicy from "./resources/RefundPolicy";
import TermsCondition from "./resources/TermsCondition";
import Scrolltop from "./common/Scrolltop";
function App() {
  const token = localStorage.getItem("Token");

  useEffect(() => {
    const scriptFile = document.createElement("script");
    scriptFile.src = "/assets/js/script.js"; //your js file path
    // scriptFile .async = true;
   
    document.body.appendChild(scriptFile);
  }, []);
  if (token) {
    return (
      <BrowserRouter>
        <div className="container-fluid">
          <div className="row ">
            <Jobheader />
            <Scrolltop/>
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/jobdetail/:id" element={<JobDetail />} />
              <Route path="/adduserdetail" element={<Multistepform />} />
              <Route path="/profile" element={<Userprofile />} />
              <Route path="/" element={<Userlanding />} />
              <Route path="/news" element={<News />} />
              <Route path="/jobs/:param" element={<Jobs />} />
              <Route path="/roleinterested" element={<Rolesinterested />} />
              <Route path="/about" element={<About />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/helpcenter" element={<HelpCenter />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/refundpolicy" element={<RefundPolicy />} />
              <Route path="/termscondition" element={<TermsCondition />} />
              <Route path="/adduserdetail" element={<Multistepform />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <div className="container-fluid">
          <div className="row ">
          <Scrolltop/>
            <Header />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/register" element={<Register />} />
              <Route path="/jobdetail/:id" element={<JobDetail />} />
              <Route path="/adduserdetail" element={<Multistepform />} />
              <Route path="/profile" element={<Userprofile />} />
              <Route path="/homepage" element={<Userlanding />} />
              <Route path="/login/:id" element={<Login />} />
              <Route path="/news" element={<News />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/roleinterested" element={<Rolesinterested />} />
              <Route path="/about" element={<About />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/helpcenter" element={<HelpCenter />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/refundpolicy" element={<RefundPolicy />} />
              <Route path="/termscondition" element={<TermsCondition />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
