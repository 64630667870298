import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Otp from "./Otp";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || "http://production";
  const [register, setRegister] = useState({
    name: "",
    email: "",
    contactNumber: "",
    education: [],
    employement: [],
    skills: [],
    rating: 0,
    workstatus: "",
    resume: "",
    profile: "",
    type: "applicant",
    password: "12345",
  });
  const[otpid,setid]=useState();
  const [selectFile, setSelectedFile] = useState("");
  const handleRegister = (e) => {
    const { name, value } = e.target;

    setRegister((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const getotponnumber =()=>{
  //   console.log(register.contactNumber)
     
    
  // }


const [isverified,setisverified]=useState(false)
  const nav = useNavigate();

  const handleFiles = (e) => {
    let file = e.target.files[0];
    setSelectedFile(file);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(register);
    if(register.name != "" &&
    register.email != "" &&
    register.contactNumber!= "" &&
    register.workstatus != "" &&
    register.password != "")
    {
      const body = {
        phonenumber: register.contactNumber
      }
      axios.post(`${API_ENDPOINT}/api/getotp`,body)
      .then((res)=>{
console.log(res.data.id);
alert(res.data.data.otp)
setid(res.data.id);
setfield(true);
      })
      .catch((err)=>{
console.log(err)
      })
     
    }
    else{
      toast.error("Please fillout the mendatory fields");
    }
  
    // console.log(selectFile);
  };
  const [field,setfield]=useState(false);
  if(field){
    return(<><Otp  register={register} otpid={otpid}/></>);
  }
  else{
    return (
      <>
        <div className="user-landing_register">
          <div className="bg_img"></div>
          <div className="col-lg-9 mx-auto">
            <div className="reg_wrps">
              <div className="row justify-content-around">
                <div className="col-lg-8">
                  <div className="reg_frm">
                    <div className="col-lg-9">
                      <div className="reg_title">
                        <h2>register account</h2>
                      </div>
                      <form onSubmit={handleSubmit}>
                      <div className="reg_field">
                        <label className="form-label" htmlFor="">
                          full name *
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="what is your name"
                          name="name"
                          onChange={handleRegister}
                        />
                      </div>
                      <div className="reg_field">
                        <label className="form-label" htmlFor="">
                          email *
                        </label>
                        <input
                          type="email"
                          className="form-control form-control-md"
                          placeholder="tell us your email id"
                          name="email"
                          onChange={handleRegister}
                        />
                        <span>
                          We will send you relevant job application and update on
                          this email
                        </span>
                      </div>
                      <div className="reg_field">
                        <label className="form-label" htmlFor="">
                          password *
                        </label>
                        <input
                          type="password"
                          className="form-control form-control-md"
                          placeholder="create a passwword for your account"
                          name="password"
                          onChange={handleRegister}
                        />
                        <span>
                          We will send you relevant job application and update on
                          this email
                        </span>
                      </div>
                      <div className="reg_field">
                        <label className="form-label" htmlFor="">
                          mobile no *
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="+91"
                          name="contactNumber"
                          onChange={handleRegister}
                        />
                        <span>Recruiter will call you on this number</span>
                      </div>
  
                      <div className="reg_field">
                        <label className="form-label" htmlFor="">
                          work status
                        </label>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="status_exp">
                              <div className="row align-items-lg-start justify-content-lg-center">
                                <div className="col-lg-3 col-md-3 col-3">
                                  <div className="st_ic text-center">
                                    <input
                                      type="radio"
                                      className="hideRadio"
                                      value="exp"
                                      name="workstatus"
                                      onClick={handleRegister}
                                    />
                                    <img
                                      src="assets/images/Icon metro-libreoffice.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-9">
                                  <div className="st_info">
                                    <h3>I am experienced</h3>
                                    <p>
                                      We will send you relevant job application
                                      and update on this email
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="status_exp">
                              <div className="row align-items-lg-start justify-content-lg-center">
                                <div className="col-lg-3 col-md-3 col-3">
                                  <div className="st_ic text-center">
                                    <input
                                      type="radio"
                                      className="hideRadio"
                                      value="fresher"
                                      name="workstatus"
                                      onClick={handleRegister}
                                    />
                                    <img
                                      src="assets/images/Icon metro-libreoffice.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-9">
                                  <div className="st_info">
                                    <h3>I am fresher</h3>
                                    <p>
                                      We will send you relevant job application
                                      and update on this email
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="reg_field">
                        <label className="form-label" htmlFor="">
                          Attach resume
                        </label>
                        <div className="reg_file resume_input">
                          <input
                            type="file"
                            className="form-control form-control-md"
                            id="inputGroupFile01"
                            name="file"
                            onChange={handleFiles}
                          />
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupFile01"
                          >
                            browse
                          </label>
                        </div>
                      </div>
  
                      
                        <button type="submit" className="">
                          next step
                        </button>
                     
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="reg_acc">
                    <div className="reg_acc_title">
                      <h2>After Registering Account you will be able to</h2>
                    </div>
  
                    <div className="rg_acc_lst">
                      <ul>
                        <li>
                          <div className="row">
                            <div className="col-lg-2">
                              <div className="acc_img">
                                <img
                                  src="assets/images/Icon feather-check-circle.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="col-lg-10">
                              <div className="acc_text">
                                <h3>I am fresher</h3>
                                <p>
                                  We will send you relevant job application and
                                  update on this email
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
  
                        <li>
                          <div className="row">
                            <div className="col-lg-2">
                              <div className="acc_img">
                                <img
                                  src="assets/images/Icon feather-check-circle.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="col-lg-10">
                              <div className="acc_text">
                                <h3>I am fresher</h3>
                                <p>
                                  We will send you relevant job application and
                                  update on this email
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
  
                        <li>
                          <div className="row">
                            <div className="col-lg-2">
                              <div className="acc_img">
                                <img
                                  src="assets/images/Icon feather-check-circle.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="col-lg-10">
                              <div className="acc_text">
                                <h3>I am fresher</h3>
                                <p>
                                  We will send you relevant job application and
                                  update on this email
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
rtl={false}
pauseOnHover={true}
theme="light"
/>
      </>
    );
  }

};

export default Register;
