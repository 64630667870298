import React from 'react'
import { NavLink } from 'react-router-dom'

const Userprofile = () => {
    return (
        <>
            <div className="user-landing">
                <div className="col-lg-9 mx-auto">
                    <div className="ussr">
                        <div className="job_detail_card card_margin">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-12">
                                    <div className="user_img text-center">
                                        <img src="assets/images/madhav.png" alt="user" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="user_stranger">
                                        <h3>madhav singh</h3>
                                        <p>Examination Coordinator @ Amity University</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="ussr_const ussr_border">
                                                <ul>
                                                    <li>New Delhi</li>
                                                    <li>1 to 5 Years</li>
                                                    <li>6,00,000-8,00,000</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-5 col-lg-5 offset-lg-1">
                                            <div className="ussr_const">
                                                <ul>
                                                    <li>+91 84689 20394</li>
                                                    <li>madhav@email.com</li>
                                                    <li>30 days</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="ussr_profile">
                                        <div className="usrp text-center">
                                            <h3>Complete your profile and Get more attention by the recruiter</h3>
                                        </div>
                                        <div className="prfl_comp text-center">
                                            <h3>Profile completeness</h3>
                                            <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100">
                                                <div className="progress-bar" style={{ width: "66%" }}>66%</div>
                                            </div>
                                        </div>
                                        <div className="prfle_complete ussr_btn text-center">
                                            <NavLink to="/profile">
                                            <button type="button" >Complete profile now</button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-9 col-12">
                            <div className="job_detail_card usetup">
                                <div className="content_tle">
                                    <h3 className="m-0">Resume</h3>
                                    <p>Resume is the most important document recruiters look for. Recruiters generally do not look at profiles without resumes.</p>
                                </div>

                                <div className="ussr_input">
                                    <div className="reg_field">
                                        <label className="form-label" for="">Attach resume</label>
                                        <div className="row align-items-lg-center">
                                            <div className="col-lg-5 col-12">
                                                <div className="reg_file">
                                                    <input type="file" className="form-control form-control-md" id="inputGroupFile01" />
                                                    <label className="input-group-text atBrowse" for="inputGroupFile01">browse</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-12">
                                                <div className="ussr_filetype">
                                                    <p>Supported Formats: doc, docx, rtf, pdf, upto 2 MB</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="job_detail_card usetup">
                                <div className="content_tle">
                                    <h3>Resume headline &nbsp; <NavLink to="#"><i className="fa-regular fa-pen-to-square"></i></NavLink></h3>
                                </div>

                                <div className="content_role">
                                    <p className="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                </div>
                            </div>

                            <div className="job_detail_card usetup">
                                <div className="content_tle">
                                    <h3>Key skills &nbsp; <NavLink to="#"><i className="fa-regular fa-pen-to-square"></i></NavLink></h3>
                                </div>
                                <div className="ussr_skill suggest_type">
                                    <label className="form-label" for="">Examiner Activities</label>
                                    <label className="form-label" for="">Examination</label>
                                    <label className="form-label" for="">Education</label>
                                    <label className="form-label" for="">Teaching</label>
                                </div>
                            </div>

                            <div className="job_detail_card usetup">
                                <div className="content_tle">
                                    <h3>Key skills &nbsp; <NavLink to="#"><i className="fa-regular fa-pen-to-square"></i></NavLink></h3>
                                </div>
                                <div className="ussr_skill suggest_type">
                                    <label className="form-label" for="">Examiner Activities</label>
                                    <label className="form-label" for="">Examination</label>
                                    <label className="form-label" for="">Education</label>
                                    <label className="form-label" for="">Teaching</label>
                                </div>
                            </div>

                            <div className="job_detail_card usetup">
                                <div className="row">
                                    <div className="col-lg-9 col-7">
                                        <div className="content_tle">
                                            <h3>Employment &nbsp; <NavLink to="#"><i className="fa-regular fa-pen-to-square"></i></NavLink></h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-5">
                                        <div className="ussr_add text-end">
                                            <NavLink to="#">Add employment</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="ussr_employed">
                                    <h3>Madhav Singh</h3>
                                    <p>Examination Coordinator @ Amity University</p>
                                </div>
                                <div className="col-lg-5 col-12">
                                    <div className="ussr_employed_fade">
                                        <h5>Full-time</h5>
                                        <p>Feb 2018 to Present (5 years 11 months) 1 Month Notice Period</p>
                                    </div>
                                </div>
                            </div>

                            <div className="job_detail_card usetup">
                                <div className="row">
                                    <div className="col-lg-9 col-7">
                                        <div className="content_tle">
                                            <h3>Education &nbsp; <NavLink to="#"><i className="fa-regular fa-pen-to-square"></i></NavLink></h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-5">
                                        <div className="ussr_add text-end">
                                            <NavLink to="#">Add employment</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="ussr_employed">
                                    <h3>M.TechBio-Chemistry/Bio-Technology</h3>
                                    <p className="m-0">Amity University</p>
                                    <p>2020-2023 Full Time</p>
                                </div>
                                <div className="ussr_addLinks">
                                    <ul>
                                        <li>
                                            <NavLink to="#">Add doctorate/PhD</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Add graduation/diploma</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Add className XII</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#">Add className X</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="job_detail_card usetup">
                                <div className="row">
                                    <div className="col-lg-9 col-7">
                                        <div className="content_tle">
                                            <h3>It skills &nbsp; <NavLink to="#"><i className="fa-regular fa-pen-to-square"></i></NavLink></h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-5">
                                        <div className="ussr_add text-end">
                                            <NavLink to="#">Add details</NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="content_role">
                                    <p className="m-0">Specify details about programming languages (such as Java, Python, C/C++, Oracle, SQL etc), softwares (Microsoft Word, Excel, Tally etc) or any other software related knowledge.</p>
                                </div>
                            </div>

                            <div className="job_detail_card usetup">
                                <div className="row">
                                    <div className="col-lg-9 col-7">
                                        <div className="content_tle">
                                            <h3>Projects &nbsp; <NavLink to="#"><i className="fa-regular fa-pen-to-square"></i></NavLink></h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-5">
                                        <div className="ussr_add text-end">
                                            <NavLink to="#">Add details</NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="content_role">
                                    <p className="m-0">Specify details about programming languages (such as Java, Python, C/C++, Oracle, SQL etc), softwares (Microsoft Word, Excel, Tally etc) or any other software related knowledge.</p>
                                </div>
                            </div>

                            <div className="job_detail_card usetup">
                                <div className="content_tle">
                                    <h3>Accomplishments &nbsp; <NavLink to="#"><i className="fa-regular fa-pen-to-square"></i></NavLink></h3>
                                </div>

                                <div className="ussr_accom">
                                    <ul>
                                        <li className="mt-3">
                                            <div className="row">
                                                <div className="col-lg-9 col-7">
                                                    <div className="prname">
                                                        <h3>Online profile</h3>
                                                        <p>Add link to online profiles (e.g. Linkedin, Facebook etc.).</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-5">
                                                    <div className="prlink text-end">
                                                        <NavLink to="#">Add</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mt-3">
                                            <div className="row">
                                                <div className="col-lg-9 col-7">
                                                    <div className="prname">
                                                        <h3>Online profile</h3>
                                                        <p>Add link to online profiles (e.g. Linkedin, Facebook etc.).</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-5">
                                                    <div className="prlink text-end">
                                                        <NavLink to="#">Add</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mt-3">
                                            <div className="row">
                                                <div className="col-lg-9 col-7">
                                                    <div className="prname">
                                                        <h3>Online profile</h3>
                                                        <p>Add link to online profiles (e.g. Linkedin, Facebook etc.).</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-5">
                                                    <div className="prlink text-end">
                                                        <NavLink to="#">Add</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mt-3">
                                            <div className="row">
                                                <div className="col-lg-9 col-7">
                                                    <div className="prname">
                                                        <h3>Online profile</h3>
                                                        <p>Add link to online profiles (e.g. Linkedin, Facebook etc.).</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-5">
                                                    <div className="prlink text-end">
                                                        <NavLink to="#">Add</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="ussr_quick job_detail_card">
                                <div className="ussr_name">
                                    <h3>Quick Links</h3>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Resume</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">upload</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Resume headline</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Key skills</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Employment</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Education</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>IT skills</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Projects</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Profile summary</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Accomplishments</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Career profile</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="link_content mb-2">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <div className="link_name">
                                                <h3>Personal details</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <div className="linktag text-end">
                                                <NavLink to="#">view all</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    )
}

export default Userprofile
