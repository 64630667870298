import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getuser } from '../components/Global';
import { Country, State, City } from 'country-state-city';

const Jobheader = () => {

  const [userdetails, setuserdetails] = useState(
    {
      name: "loading...",
      prefrences: [{ resumeheadline: "loading...." }]
    });

    const [mylocationsfinal, setmylocation] = useState([{ nmae: "" }]);

  const [data, setData] = useState(
    {
      query: "",
      experience: "",
      city: "",
    }
  )
  const logout = () => {
    localStorage.removeItem("Token");
    window.location.href = "/";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const findjobs = () => {
    window.location.href = `/jobs/all?city=${encodeURIComponent(data.city)}&query=${encodeURIComponent(data.query)}&experience=${encodeURIComponent(data.experience)}`;

  }

  useEffect(() => {
    window.portal();
    getuser().then((result) => {
      setuserdetails(result)
    })

    const mylocations = [];
    const templocation = City.getCitiesOfCountry('IN')

    for (let x = 0; x < templocation.length; x++) {

      mylocations.push({ value: templocation[x].name, label: templocation[x].name })
    }
    setmylocation(mylocations)
  }, []);
  return (
    <>
      <div className="col-lg-11 mx-auto" id="jobLogout">
        <nav className="navbar navbar-expand-lg header2">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <input
                    type="text"
                    className="form-control form-control-md"
                    placeholder="Search jobs"
                    id="search_input"
                  />
                  <NavLink to="#">
                    <img
                      src="/assets/images/Icon feather-search.png"
                      alt="search"
                      className="img-fluid"
                    />
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" aria-current="page" to="/jobs/all">
                    Jobs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="#">
                    Schools
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="#">
                    Services
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="#">
                    <i className="fa-regular fa-bell bell_notify"></i>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" id="bars" to="#">
                    <span>
                      <i className="fa-solid fa-bars"></i>
                    </span>{" "}
                    &nbsp; {userdetails.name}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div class="bg-overlay"></div>
      <section className="" id="sideOver">
        <div className="logout-sidebar">
          <div class="side-close text-end">
            <NavLink>
              <i class="fa-solid fa-xmark"></i>
            </NavLink>
          </div>

          <div class="profile-sidebar">
            <div class="row">
              <div class="col-lg-4">
                <div class="sidebar_img text-center">
                  <img
                    src="/assets/images/madhav.png"
                    alt="user"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="col-lg-8">
                <div class="sidebar_content">
                  <NavLink to="/"><h2>{userdetails.name}</h2></NavLink>
                  <p>{userdetails.prefrences[0].resumeheadline}</p>
                  <NavLink to="/profile">view & update profile</NavLink>
                </div>
              </div>
            </div>
            <div class="profile_link">
              <div class="row">
                <div class="col-lg-8">
                  <div class="profile_p">
                    <h3>your profile performance</h3>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="profile_p text-end">
                    <h6>Last 90 days</h6>
                  </div>
                </div>
              </div>

              <div class="srch_appear">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="srchlft text-center">
                      <h3>0</h3>
                      <h5>search appearance</h5>
                      <NavLink to="#">view all</NavLink>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="srchlft text-center">
                      <h3>0</h3>
                      <h5>recuiter actions</h5>
                      <NavLink to="#">view all</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sidebar_logout">
                <ul>
                  <li>
                    <NavLink to="/blogs">
                      <span>
                        <i class="fa-solid fa-list"></i>
                      </span>{" "}
                      naukri blog
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <span>
                        <i class="fa-solid fa-gear"></i>
                      </span>{" "}
                      settings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <span>
                        <i class="fa-regular fa-circle-question"></i>
                      </span>{" "}
                      FAQs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#" onClick={logout}>
                      <span>
                        <i class="fa-solid fa-right-from-bracket"></i>
                      </span>{" "}
                      LogOut
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="search_job" className="px-0">
        <div className="search_header">
          <div className="row">
            <div className="col-lg-4">
              <div className="srch_edu text-center py-3">
                <h3>EDU JOBS</h3>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="close_btn text-end">
                <NavLink to="#" className="text-left pull-left closedHeader">
                  {" "}
                  <i className="fa fa-close"></i>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-lg-9 mx-auto">
            <div className="overlay_input">
              <div className="row align-items-lg-center">
                <div className="col-lg-4">
                  <div className="banner_search">
                    <input
                      type="text"
                      placeholder="keyword/companies/designation...."
                      name="query"
                      className="form-control form-control-md"
                      id=""
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="exp_search">
                    <select
                      className="form-select"
                      name="experience"
                      id=""
                      onChange={handleChange}
                    >
                      <option value=" ">Select</option>
                      <option value="fresher">Fresher</option>
                      <option value="fresher">Below 1 year</option>
                      <option value="1">1 year</option>
                      <option value="2">2 year</option>
                      <option value="3">3 year</option>
                      <option value="4">4 year</option>
                      <option value="5">5 year</option>

                    </select>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="location_search">
                    <select
                      className="form-select"
                      name="city"
                      id=""
                      onChange={handleChange}
                    >
                      <option value=" ">Select</option>
                      {mylocationsfinal.map((data, index) => {
                        return (<>
                          <option value={data.value}>{data.value}</option>
                        </>)
                      })}


                    </select>
                  </div>
                </div>
                <div className="col-lg-1">
                  <div className="fin_btn text-end">
                    <button type="submit" onClick={findjobs}>Find</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Jobheader;
