import React from "react";
import Similarjobs from "../common/Similarjobs";

function Rolesinterested() {
  const jbt = [1, 2, 3];
  return (
    <div class="user-landing">
      <div class="col-lg-9 mx-auto mb-5">
        <div class="user_wrap">
          <div class="row">
            <div class="col-lg-8">
              <div
                className="succescard text-center p-5  mb-4"
                style={{
                  background: "white",
                  borderRadius: "20px",
                  border: "3px solid #37CB21",
                }}
              >
                <div
                  className="righttivk p-3 text-center my-2"
                  style={{
                    background: "#37CB21",
                    width: "50px",
                    height: "50px",
                    borderRadius: "40px",
                    margin: "auto",
                  }}
                >
                  <i style={{ color: "white" }} class="fa-solid fa-check"></i>
                </div>
                <p
                  className="mesade my-2"
                  style={{
                    color: "#37CB21",
                    fontSize: "24px",
                    fontWeight: "bolder",
                  }}
                >
                  You have successfully applied to 'Lecturer'
                </p>
                <p className="mesade my-2" style={{ fontSize: "16px" }}>
                  Want callbacks from recruiters? Click here to highlight your
                  profile and increase your chances
                </p>
                <p
                  className="mesade my-2"
                  style={{ fontSize: "18px", fontWeight: "bolder" }}
                >
                  Call 1800-3010-5557 now! (Toll-free)
                </p>
              </div>
              <div class="job_card_title mb-4">
                <h3>15 Roles you might be interested in</h3>
              </div>
              <div class="job_desc">
                <ul>
                  {jbt.map((item, index) => {
                    return (
                      <li key={index}>
                        <div class="row">
                          <div class="col-lg-1 col-2">
                            <div class="jb_title">dps</div>
                          </div>
                          <div class="col-lg-9 col-7">
                            <div class="jb_name">
                              <h3>Faculty in Architecture For GATE Exam</h3>
                              <p>Delhi public school</p>
                            </div>
                          </div>
                          <div class="col-lg-2 col-3">
                            <div class="jb_save text-end">
                              <h5>Save this job</h5>
                            </div>
                          </div>
                        </div>
                        <div class="row pt-4">
                          <div class="col-lg-2 col-4">
                            <div class="jb_exp text-end">
                              <h4>1 to 5 Years</h4>
                            </div>
                          </div>
                          <div class="col-lg-3 col-5">
                            <div class="jb_prce text-end">
                              <h4>6,00,000-8,00,000</h4>
                            </div>
                          </div>
                          <div class="col-lg-2 col-3">
                            <div class="jb_place text-end">
                              <h4>New Delhi</h4>
                            </div>
                          </div>
                        </div>
                        <div class="jb_desc">
                          <p>
                            We require faculty who can teach for GATE exam and
                            other competitive Architecture exams of
                            post-graduate level. Job involves teaching in
                            offline/online classes, development of study
                            material and tests, videos, etc. Good past GATE rank
                            required.
                          </p>
                        </div>
                        <div class="jb_time">
                          <p>5s ago</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div class="col-lg-4">
              <Similarjobs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rolesinterested;
