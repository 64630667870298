import React from 'react'
import { NavLink } from 'react-router-dom'
function News() {
  return (
    <section className="citiessecion py-5">
    <div className="container my-4">
      <div className="row text-center">
        <div className="heading mb-5">
          <h2>News</h2>
        </div>
        </div>
        <div className='row'>
          <div className='col-md-4'>
          <div className=" slide_card">
              <div className="slide_title">
                <div className="slide_img">
                  <img
                    src="assets/images/maxresdefault.png"
                    alt="news_insights_1"
                    className="img-fluid"
                  />
                </div>
                <div className="slide_name">
                  <h3>Legal battles for family if die intestate</h3>
                  <p>
                    Writing a will becomes imperative to ensuring that an
                    estate is devolved as per the wishes of the Testator. In
                    the absence of the same, succession of their assets.
                  </p>
                </div>
              </div>
              <div className="slide_btn">
                <NavLink to="/blogs">
                  read more{" "}
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
          <div className=" slide_card">
              <div className="slide_title">
                <div className="slide_img">
                  <img
                    src="assets/images/maxresdefault.png"
                    alt="news_insights_1"
                    className="img-fluid"
                  />
                </div>
                <div className="slide_name">
                  <h3>Legal battles for family if die intestate</h3>
                  <p>
                    Writing a will becomes imperative to ensuring that an
                    estate is devolved as per the wishes of the Testator. In
                    the absence of the same, succession of their assets.
                  </p>
                </div>
              </div>
              <div className="slide_btn">
                <NavLink to="/blogs">
                  read more{" "}
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
          <div className=" slide_card">
              <div className="slide_title">
                <div className="slide_img">
                  <img
                    src="assets/images/maxresdefault.png"
                    alt="news_insights_1"
                    className="img-fluid"
                  />
                </div>
                <div className="slide_name">
                  <h3>Legal battles for family if die intestate</h3>
                  <p>
                    Writing a will becomes imperative to ensuring that an
                    estate is devolved as per the wishes of the Testator. In
                    the absence of the same, succession of their assets.
                  </p>
                </div>
              </div>
              <div className="slide_btn">
                <NavLink to="/blogs">
                  read more{" "}
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
          <div className=" slide_card">
              <div className="slide_title">
                <div className="slide_img">
                  <img
                    src="assets/images/maxresdefault.png"
                    alt="news_insights_1"
                    className="img-fluid"
                  />
                </div>
                <div className="slide_name">
                  <h3>Legal battles for family if die intestate</h3>
                  <p>
                    Writing a will becomes imperative to ensuring that an
                    estate is devolved as per the wishes of the Testator. In
                    the absence of the same, succession of their assets.
                  </p>
                </div>
              </div>
              <div className="slide_btn">
                <NavLink to="/blogs">
                  read more{" "}
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
          <div className=" slide_card">
              <div className="slide_title">
                <div className="slide_img">
                  <img
                    src="assets/images/maxresdefault.png"
                    alt="news_insights_1"
                    className="img-fluid"
                  />
                </div>
                <div className="slide_name">
                  <h3>Legal battles for family if die intestate</h3>
                  <p>
                    Writing a will becomes imperative to ensuring that an
                    estate is devolved as per the wishes of the Testator. In
                    the absence of the same, succession of their assets.
                  </p>
                </div>
              </div>
              <div className="slide_btn">
                <NavLink to="/blogs">
                  read more{" "}
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
          <div className=" slide_card">
              <div className="slide_title">
                <div className="slide_img">
                  <img
                    src="assets/images/maxresdefault.png"
                    alt="news_insights_1"
                    className="img-fluid"
                  />
                </div>
                <div className="slide_name">
                  <h3>Legal battles for family if die intestate</h3>
                  <p>
                    Writing a will becomes imperative to ensuring that an
                    estate is devolved as per the wishes of the Testator. In
                    the absence of the same, succession of their assets.
                  </p>
                </div>
              </div>
              <div className="slide_btn">
                <NavLink to="/blogs">
                  read more{" "}
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
          <div className=" slide_card">
              <div className="slide_title">
                <div className="slide_img">
                  <img
                    src="assets/images/maxresdefault.png"
                    alt="news_insights_1"
                    className="img-fluid"
                  />
                </div>
                <div className="slide_name">
                  <h3>Legal battles for family if die intestate</h3>
                  <p>
                    Writing a will becomes imperative to ensuring that an
                    estate is devolved as per the wishes of the Testator. In
                    the absence of the same, succession of their assets.
                  </p>
                </div>
              </div>
              <div className="slide_btn">
                <NavLink to="/blogs">
                  read more{" "}
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        </div>
        </section>
  )
}

export default News