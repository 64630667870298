import React from 'react'

const RefundPolicy = () => {
  return (
   <>
      <section className="citiessecion py-5">
        <div className="container my-4">
          <div className="row text-center">
            <div className="heading mb-5">
              <h2>Privacy Policy</h2>
            </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
              <section>
        <p>This Refund Policy applies to purchases made through [Your Company Name]. Please read this policy carefully before making any transactions with us.</p>
    </section>

    <section>
        <h2>Refund Eligibility</h2>
        <p>We may issue refunds under the following circumstances:</p>
        <ul>
            <li><strong>Product/Service Defects:</strong> If the product or service you purchased is defective or does not meet the specified standards.</li>
            <li><strong>Unavailability:</strong> If the product or service becomes permanently unavailable after your purchase.</li>
            <li><strong>Unauthorized Transactions:</strong> If there are unauthorized transactions made using your payment method.</li>
        </ul>
    </section>

    <section>
        <h2>Refund Requests</h2>
        <p>To request a refund, please contact our customer support team within [number of days] days of the purchase date. Provide your order number and detailed information about the reason for the refund request.</p>
    </section>

    <section>
        <h2>Refund Process</h2>
        <p>Once your refund request is received and approved, we will process the refund within [number of days] days. The refund will be issued to the original payment method used for the purchase.</p>
    </section>

    <section>
        <h2>Non-Refundable Items</h2>
        <p>Certain items may not be eligible for a refund, including but not limited to:</p>
        <ul>
            <li>Downloadable products or digital content.</li>
            <li>Services that have already been provided.</li>
            <li>Customized or personalized items.</li>
        </ul>
    </section>

    <section>
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about our Refund Policy, please contact us at [your contact email address].</p>
    </section>

              </div>
            </div>
            </div>
            </section>
   </>
  )
}

export default RefundPolicy